import ConstructedCvActivity from '../ConstructedCvActivity';
import SpecializationsResource from '../../../../../../../tools/resources/types/business/specializations/SpecializationsResource';
import ConstructedCvActivityPosition from '../../fields/position/constructed/ConstructedCvActivityPosition';

import updateConstructedActivityPositionWithSpecializationsResource from '../../fields/position/constructed/operations/updateConstructedActivityPositionWithSpecializationsResource';

const updateConstructedActivityWithSpecializationsResource = (
  constructedActivity: ConstructedCvActivity,
  specializationsResource: SpecializationsResource,
): ConstructedCvActivity => ({
  ...constructedActivity,
  position: updateConstructedActivityPositionWithSpecializationsResource(
    constructedActivity.position as ConstructedCvActivityPosition,
    specializationsResource,
  ),
});

export default updateConstructedActivityWithSpecializationsResource;
