import React from 'react';

import { Box } from '@mui/material';
import { BarChart, ViewStream } from '@mui/icons-material';

import useI18n from '../../../../../hooks/internationalization/useI18n';

import { ComponentProps } from '../../../ComponentProps';
import IconToggleAtom from '../../../atoms/toggles/icon/IconToggleAtom';
import TooltipAtom from '../../../atoms/tooltips/TooltipAtom';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { TOGGLE_VIEW_LIST_CHART_TOOLTIP_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type ListChartToggleMoleculeProps = ComponentProps & {
  checked?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
};

const ListChartToggleMolecule: React.FC<ListChartToggleMoleculeProps> = ({
  className,
  checked,
  onChange,
}) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);

  return (
    <TooltipAtom
      className='list-chart-toggle__tooltip'
      title={t(TOGGLE_VIEW_LIST_CHART_TOOLTIP_MAPPING)}
      arrow>
      <Box className=' list-chart-toggle__tooltip-wrapper'>
        <IconToggleAtom
          className={`${className} list-chart-toggle`}
          checked={checked}
          icon={<ViewStream />}
          checkedIcon={<BarChart sx={{ rotate: '90deg' }} />}
          onChange={onChange}
        />
      </Box>
    </TooltipAtom>
  );
};

export default ListChartToggleMolecule;
