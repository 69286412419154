import React, { useEffect, useRef, useState } from 'react';

interface UseElementStickiness {
  elementRef: React.RefObject<Element>;
  isSticky: boolean;
}

type ElementVisibilityOptions = {
  threshold?: number;
};

const useElementStickiness = (
  options: ElementVisibilityOptions,
): UseElementStickiness => {
  const elementRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsSticky(entry.intersectionRatio < 1),
      options,
    );

    let refValue: Element | null = null;

    if (elementRef.current) {
      observer.observe(elementRef.current);
      refValue = elementRef.current;
    }

    return () => {
      if (refValue) {
        observer.unobserve(refValue);
      }
    };
  }, [elementRef, options]);

  return {
    elementRef,
    isSticky,
  };
};

export default useElementStickiness;
