import loadResource, { LoadResource } from '../loadResource';

import I18nLanguage from '../../../../tools/internationalization/i18n/types/I18nLanguage';
import TagsResource from '../../../../tools/resources/types/business/tags/TagsResource';

import {
  LOAD_SPECIALIZATIONS_RESOURCE_ERROR,
  LOAD_SPECIALIZATIONS_RESOURCE_REQUEST,
  LOAD_SPECIALIZATIONS_RESOURCE_RESPONSE,
} from '../resourceActionTypes';
import RESOURCES_API from '../../../../tools/api/resources';

export type LoadSpecializationsResource = LoadResource<
  typeof LOAD_SPECIALIZATIONS_RESOURCE_REQUEST,
  typeof LOAD_SPECIALIZATIONS_RESOURCE_RESPONSE,
  typeof LOAD_SPECIALIZATIONS_RESOURCE_ERROR,
  TagsResource
>;

const loadSpecializationsResource = (language: I18nLanguage) =>
  loadResource(
    LOAD_SPECIALIZATIONS_RESOURCE_REQUEST,
    LOAD_SPECIALIZATIONS_RESOURCE_RESPONSE,
    LOAD_SPECIALIZATIONS_RESOURCE_ERROR,
    () => RESOURCES_API.getSpecializations(language),
  );

export default loadSpecializationsResource;
