import React from 'react';

interface UseWindowDimensions {
  innerWidth: number;
}

const useWindowDimensions = (): UseWindowDimensions => {
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { innerWidth };
};

export default useWindowDimensions;
