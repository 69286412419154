import CvActivity from '../../CvActivity';
import ConstructedCvActivity from '../ConstructedCvActivity';

import toCvActivityPosition from '../../fields/position/constructed/converters/toCvActivityPosition';
import isCvActivityProject from '../../fields/project/guards/isCvActivityProject';
import isCvActivityProvider from '../../fields/provider/guards/isCvActivityProvider';
import toCvActivitySubactivities from '../../fields/subactivities/constructed/converters/toCvActivitySubactivities';

const toCvActivity = (
  constructedCvActivity: ConstructedCvActivity,
): CvActivity => ({
  id: constructedCvActivity.id,
  heirId: constructedCvActivity.heirId,
  type: constructedCvActivity.type,
  period: constructedCvActivity.period,
  position: toCvActivityPosition(constructedCvActivity.position),
  project: isCvActivityProject(constructedCvActivity.project)
    ? constructedCvActivity.project
    : undefined,
  provider: isCvActivityProvider(constructedCvActivity.provider)
    ? constructedCvActivity.provider
    : undefined,
  subactivities: toCvActivitySubactivities(constructedCvActivity.subactivities),
});

export default toCvActivity;
