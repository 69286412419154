import React from 'react';

import { BarChart, BarChartProps } from '@mui/x-charts';

type BarChartAtomProps = BarChartProps & {
  xAxisHidden?: boolean;
  yAxisHidden?: boolean;
  firstTickHidden?: boolean;
};

const BarChartAtom: React.FC<BarChartAtomProps> = ({
  className,
  skipAnimation = true,
  disableAxisListener = false,
  xAxisHidden = false,
  yAxisHidden = false,
  firstTickHidden = false,
  dataset,
  yAxis,
  xAxis,
  series,
  layout,
  grid,
  width,
  height,
  margin,
  borderRadius,
  ...props
}) => {
  const customSx = {
    '&&': {
      touchAction: 'auto',
    },
    '& .MuiChartsAxis-directionX': {
      display: xAxisHidden ? 'none' : 'block',
    },
    '& .MuiChartsAxis-directionY': {
      display: yAxisHidden ? 'none' : 'block',
    },
    '& .MuiChartsAxis-tickContainer': {
      '&:first-of-type': {
        '& .MuiChartsAxis-tick': {
          display: firstTickHidden ? 'none' : 'block',
        },
      },
      '& .MuiChartsAxis-tickLabel': {
        fontSize: '1rem !important',
      },
    },
  };

  return (
    <BarChart
      className={className}
      skipAnimation={skipAnimation}
      disableAxisListener={disableAxisListener}
      dataset={dataset}
      yAxis={yAxis}
      xAxis={xAxis}
      series={series}
      layout={layout}
      grid={grid}
      width={width}
      height={height}
      borderRadius={borderRadius}
      margin={margin}
      sx={customSx}
      {...props}
    />
  );
};

export default BarChartAtom;
