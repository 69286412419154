import PositionResource from '../../../../../../../../../tools/resources/types/business/positions/PositionResource';
import ConstructedCvUserPosition from '../ConstructedCvUserPosition';

const createConstructedUserPositionFromResource = (
  positionResource?: PositionResource,
): ConstructedCvUserPosition | undefined =>
  positionResource && {
    id: positionResource.id,
    name: positionResource.name,
    specialization: positionResource.specializationId,
  };

export default createConstructedUserPositionFromResource;
