import Loadable from '../modifiers/Loadable';
import Resource from '../../tools/resources/types/Resource';
import LoadableResource from '../../tools/resources/types/LoadableResource';
import VersionResource from '../../tools/resources/types/business/version/VersionResource';
import LanguageBundlesResource from '../../tools/resources/types/business/languageBundles/LanguageBundlesResource';
import CvResource from '../../tools/resources/types/business/cv/CvResource';
import UserResource from '../../tools/resources/types/business/users/UserResource';
import ActivitiesResource from '../../tools/resources/types/business/activities/ActivitiesResource';
import SubactivitiesResource from '../../tools/resources/types/business/subactivities/SubactivitiesResource';
import ProjectsResource from '../../tools/resources/types/business/projects/ProjectsResource';
import ProvidersResource from '../../tools/resources/types/business/providers/ProvidersResource';
import PositionsResource from '../../tools/resources/types/business/positions/PositionsResource';
import SkillsResource from '../../tools/resources/types/business/skills/SkillsResource';
import TagsResource from '../../tools/resources/types/business/tags/TagsResource';
import SpecializationsResource from '../../tools/resources/types/business/specializations/SpecializationsResource';

import * as actionType from '../actions/resources/resourceActionTypes';
import { LoadVersionResource } from '../actions/resources/load/loadVersionResource';
import { LoadLanguageBundlesResource } from '../actions/resources/load/loadLanguageBundlesResource';
import { LoadCvResource } from '../actions/resources/load/loadCvResource';
import { LoadUserResource } from '../actions/resources/load/loadUserResource';
import { LoadActivitiesResource } from '../actions/resources/load/loadActivitiesResource';
import { LoadSubactivitiesResource } from '../actions/resources/load/loadSubactivitiesResource';
import { LoadProjectsResource } from '../actions/resources/load/loadProjectsResource';
import { LoadProvidersResource } from '../actions/resources/load/loadProvidersResource';
import { LoadPositionsResource } from '../actions/resources/load/loadPositionsResource';
import { LoadSkillsResource } from '../actions/resources/load/loadSkillsResource';
import { LoadTagsResource } from '../actions/resources/load/loadTagsResource';
import { LoadSpecializationsResource } from '../actions/resources/load/loadSpecializationsResource';
import { RequireCvResource } from '../actions/resources/require/requireCvResource';
import { RequireUserResource } from '../actions/resources/require/requireUserResource';
import { RequireActivitiesResource } from '../actions/resources/require/requireActivitiesResource';
import { RequireSubactivitiesResource } from '../actions/resources/require/requireSubactivitiesResource';
import { RequireProjectsResource } from '../actions/resources/require/requireProjectsResource';
import { RequireProvidersResource } from '../actions/resources/require/requireProvidersResource';
import { RequirePositionsResource } from '../actions/resources/require/requirePositionsResource';
import { RequireSkillsResource } from '../actions/resources/require/requireSkillsResource';
import { RequireTagsResource } from '../actions/resources/require/requireTagsResource';
import { RequireSpecializationsResource } from '../actions/resources/require/requireSpecializationsResource';

export type ResourcesStoreEntry<T extends Resource> = Loadable & {
  value: LoadableResource<T>;
  error?: string;
};

export type RequestedResourceStoreEntry<T extends Resource> =
  ResourcesStoreEntry<T> & {
    required: boolean;
  };

interface ResourcesStore {
  version: ResourcesStoreEntry<VersionResource>;
  languageBundles: ResourcesStoreEntry<LanguageBundlesResource>;
  cv: RequestedResourceStoreEntry<CvResource>;
  user: RequestedResourceStoreEntry<UserResource>;
  activities: RequestedResourceStoreEntry<ActivitiesResource>;
  subactivities: RequestedResourceStoreEntry<SubactivitiesResource>;
  projects: RequestedResourceStoreEntry<ProjectsResource>;
  providers: RequestedResourceStoreEntry<ProvidersResource>;
  positions: RequestedResourceStoreEntry<PositionsResource>;
  skills: RequestedResourceStoreEntry<SkillsResource>;
  tags: RequestedResourceStoreEntry<TagsResource>;
  specializations: RequestedResourceStoreEntry<SpecializationsResource>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialResourceEntryState: ResourcesStoreEntry<any> = {
  value: null,
  loading: false,
  error: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialRequestedResourceEntryState: RequestedResourceStoreEntry<any> = {
  ...initialResourceEntryState,
  required: false,
};

const initialState: ResourcesStore = {
  version: initialResourceEntryState,
  languageBundles: initialResourceEntryState,
  cv: initialRequestedResourceEntryState,
  user: initialRequestedResourceEntryState,
  activities: initialRequestedResourceEntryState,
  subactivities: initialRequestedResourceEntryState,
  projects: initialRequestedResourceEntryState,
  providers: initialRequestedResourceEntryState,
  positions: initialRequestedResourceEntryState,
  skills: initialRequestedResourceEntryState,
  tags: initialRequestedResourceEntryState,
  specializations: initialRequestedResourceEntryState,
};

type ResourcesReducerTypes =
  | LoadVersionResource
  | LoadLanguageBundlesResource
  | RequireCvResource
  | LoadCvResource
  | RequireUserResource
  | LoadUserResource
  | RequireActivitiesResource
  | LoadActivitiesResource
  | RequireSubactivitiesResource
  | LoadSubactivitiesResource
  | RequireProjectsResource
  | LoadProjectsResource
  | RequireProvidersResource
  | LoadProvidersResource
  | RequirePositionsResource
  | LoadPositionsResource
  | RequireSkillsResource
  | LoadSkillsResource
  | RequireTagsResource
  | LoadTagsResource
  | RequireSpecializationsResource
  | LoadSpecializationsResource;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onRequirementRequestedResourceState: RequestedResourceStoreEntry<any> = {
  value: null,
  loading: false,
  error: undefined,
  required: true,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onLoadingResourceState: ResourcesStoreEntry<any> = {
  value: null,
  loading: true,
  error: undefined,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onLoadingRequestedResourceState: RequestedResourceStoreEntry<any> = {
  value: null,
  loading: true,
  error: undefined,
  required: false,
};

const onLoadedResourceState = <T extends Resource>(
  value: T,
): ResourcesStoreEntry<T> => ({
  value,
  loading: false,
  error: undefined,
});

const onLoadedRequestedResourceState = <T extends Resource>(
  value: T,
): RequestedResourceStoreEntry<T> => ({
  value,
  loading: false,
  error: undefined,
  required: true,
});

const onErrorResourceState = <T extends Resource>(
  errorMsg: string,
): ResourcesStoreEntry<T> => ({
  value: null,
  loading: false,
  error: errorMsg,
});

const onErrorRequestedResourceState = <T extends Resource>(
  errorMsg: string,
): RequestedResourceStoreEntry<T> => ({
  value: null,
  loading: false,
  error: errorMsg,
  required: false,
});

export const resourcesReducer = (
  state = initialState,
  action: ResourcesReducerTypes,
): ResourcesStore => {
  switch (action.type) {
    case actionType.LOAD_CV_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        cv: onRequirementRequestedResourceState,
      };
    }
    case actionType.LOAD_USER_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        user: onRequirementRequestedResourceState,
      };
    }
    case actionType.LOAD_ACTIVITIES_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        activities: onRequirementRequestedResourceState,
      };
    }
    case actionType.LOAD_SUBACTIVITIES_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        subactivities: onRequirementRequestedResourceState,
      };
    }
    case actionType.LOAD_PROJECTS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        projects: onRequirementRequestedResourceState,
      };
    }
    case actionType.LOAD_PROVIDERS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        providers: onRequirementRequestedResourceState,
      };
    }
    case actionType.LOAD_POSITIONS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        positions: onRequirementRequestedResourceState,
      };
    }
    case actionType.LOAD_SKILLS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        skills: onRequirementRequestedResourceState,
      };
    }
    case actionType.LOAD_TAGS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        tags: onRequirementRequestedResourceState,
      };
    }
    case actionType.LOAD_SPECIALIZATIONS_RESOURCE_REQUIREMENT: {
      return {
        ...state,
        specializations: onRequirementRequestedResourceState,
      };
    }
    case actionType.LOAD_VERSION_RESOURCE_REQUEST: {
      return {
        ...state,
        version: onLoadingResourceState,
      };
    }
    case actionType.LOAD_LANGUAGE_BUNDLES_RESOURCE_REQUEST: {
      return {
        ...state,
        languageBundles: onLoadingResourceState,
      };
    }
    case actionType.LOAD_CV_RESOURCE_REQUEST: {
      return {
        ...state,
        cv: onLoadingRequestedResourceState,
      };
    }
    case actionType.LOAD_USER_RESOURCE_REQUEST: {
      return {
        ...state,
        user: onLoadingRequestedResourceState,
      };
    }
    case actionType.LOAD_ACTIVITIES_RESOURCE_REQUEST: {
      return {
        ...state,
        activities: onLoadingRequestedResourceState,
      };
    }
    case actionType.LOAD_SUBACTIVITIES_RESOURCE_REQUEST: {
      return {
        ...state,
        subactivities: onLoadingRequestedResourceState,
      };
    }
    case actionType.LOAD_PROJECTS_RESOURCE_REQUEST: {
      return {
        ...state,
        projects: onLoadingRequestedResourceState,
      };
    }
    case actionType.LOAD_PROVIDERS_RESOURCE_REQUEST: {
      return {
        ...state,
        providers: onLoadingRequestedResourceState,
      };
    }
    case actionType.LOAD_POSITIONS_RESOURCE_REQUEST: {
      return {
        ...state,
        positions: onLoadingRequestedResourceState,
      };
    }
    case actionType.LOAD_SKILLS_RESOURCE_REQUEST: {
      return {
        ...state,
        skills: onLoadingRequestedResourceState,
      };
    }
    case actionType.LOAD_TAGS_RESOURCE_REQUEST: {
      return {
        ...state,
        tags: onLoadingRequestedResourceState,
      };
    }
    case actionType.LOAD_SPECIALIZATIONS_RESOURCE_REQUEST: {
      return {
        ...state,
        specializations: onLoadingRequestedResourceState,
      };
    }
    case actionType.LOAD_VERSION_RESOURCE_RESPONSE: {
      return {
        ...state,
        version: onLoadedResourceState(action.payload),
      };
    }
    case actionType.LOAD_LANGUAGE_BUNDLES_RESOURCE_RESPONSE: {
      return {
        ...state,
        languageBundles: onLoadedResourceState(action.payload),
      };
    }
    case actionType.LOAD_CV_RESOURCE_RESPONSE: {
      return {
        ...state,
        cv: onLoadedRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_USER_RESOURCE_RESPONSE: {
      return {
        ...state,
        user: onLoadedRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_ACTIVITIES_RESOURCE_RESPONSE: {
      return {
        ...state,
        activities: onLoadedRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_SUBACTIVITIES_RESOURCE_RESPONSE: {
      return {
        ...state,
        subactivities: onLoadedRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_PROJECTS_RESOURCE_RESPONSE: {
      return {
        ...state,
        projects: onLoadedRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_PROVIDERS_RESOURCE_RESPONSE: {
      return {
        ...state,
        providers: onLoadedRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_POSITIONS_RESOURCE_RESPONSE: {
      return {
        ...state,
        positions: onLoadedRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_SKILLS_RESOURCE_RESPONSE: {
      return {
        ...state,
        skills: onLoadedRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_TAGS_RESOURCE_RESPONSE: {
      return {
        ...state,
        tags: onLoadedRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_SPECIALIZATIONS_RESOURCE_RESPONSE: {
      return {
        ...state,
        specializations: onLoadedRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_VERSION_RESOURCE_ERROR: {
      return {
        ...state,
        version: onErrorResourceState(action.payload),
      };
    }
    case actionType.LOAD_LANGUAGE_BUNDLES_RESOURCE_ERROR: {
      return {
        ...state,
        languageBundles: onErrorResourceState(action.payload),
      };
    }
    case actionType.LOAD_CV_RESOURCE_ERROR: {
      return {
        ...state,
        cv: onErrorRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_USER_RESOURCE_ERROR: {
      return {
        ...state,
        user: onErrorRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_ACTIVITIES_RESOURCE_ERROR: {
      return {
        ...state,
        activities: onErrorRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_SUBACTIVITIES_RESOURCE_ERROR: {
      return {
        ...state,
        subactivities: onErrorRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_PROJECTS_RESOURCE_ERROR: {
      return {
        ...state,
        projects: onErrorRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_PROVIDERS_RESOURCE_ERROR: {
      return {
        ...state,
        providers: onErrorRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_POSITIONS_RESOURCE_ERROR: {
      return {
        ...state,
        positions: onErrorRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_SKILLS_RESOURCE_ERROR: {
      return {
        ...state,
        skills: onErrorRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_TAGS_RESOURCE_ERROR: {
      return {
        ...state,
        tags: onErrorRequestedResourceState(action.payload),
      };
    }
    case actionType.LOAD_SPECIALIZATIONS_RESOURCE_ERROR: {
      return {
        ...state,
        specializations: onErrorRequestedResourceState(action.payload),
      };
    }
    default:
      return state;
  }
};
