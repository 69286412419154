import setValue, { SetOptionalValue } from '../../../setValue';

import CvUser from '../../../../../types/business/cv/fields/user/CvUser';

import { SET_ACTUAL_CV_USER } from '../../cvActionTypes';

export type SetActualCvUser = SetOptionalValue<
  typeof SET_ACTUAL_CV_USER,
  CvUser
>;

const setActualCvUser = (cvUser?: CvUser) =>
  setValue(SET_ACTUAL_CV_USER, cvUser);

export default setActualCvUser;
