import React, { useEffect, useMemo } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import useI18n from '../../../../../hooks/internationalization/useI18n';
import useCvFiltering from '../../../../../hooks/business/cv/useCvFiltering';

import FilterData from '../../../../../types/business/filter/data/FilterData';
import ActivityTypeFilterData from '../../../../../types/business/filter/data/variants/type/ActivityTypeFilterData';
import ActivityPositionFilterData from '../../../../../types/business/filter/data/variants/position/ActivityPositionFilterData';
import PositionSpecializationFilterData from '../../../../../types/business/filter/data/variants/specialization/PositionSpecializationFilterData';
import ActivityPeriodFilterData from '../../../../../types/business/filter/data/variants/period/ActivityPeriodFilterData';

import DialogMoleculeProps from '../DialogMoleculeProps';
import ButtonAtom from '../../../atoms/buttons/regular/ButtonAtom';
import ActivityTypeFilterDialogMoleculeAccordion from './accordions/variants/ActivityTypeFilterDialogMoleculeAccordion';
import ActivityPositionFilterDialogMoleculeAccordion from './accordions/variants/ActivityPositionFilterDialogMoleculeAccordion';
import ActivityPeriodFilterDialogMoleculeAccordion from './accordions/variants/ActivityPeriodFilterDialogMoleculeAccordion';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import {
  DIALOG_FILTER_APPLY_MAPPING,
  DIALOG_FILTER_CLOSE_MAPPING,
  DIALOG_FILTER_RESET_ALL_MAPPING,
  DIALOG_FILTER_TITLE_MAPPING,
} from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';
import {
  ACTIVITY_PERIOD_FILTER,
  ACTIVITY_POSITION_FILTER,
  POSITION_SPECIALIZATION_FILTER,
  ACTIVITY_TYPE_FILTER,
} from '../../../../../types/business/filter/data/prototype/fields/FilterType';
import PositionSpecializationFilterDialogMoleculeAccordion from './accordions/variants/PositionSpecializationFilterDialogMoleculeAccordion';

type FilterScheme = {
  activityType: ActivityTypeFilterData[];
  activityPosition: ActivityPositionFilterData[];
  positionSpecialization: PositionSpecializationFilterData[];
  activityPeriod: ActivityPeriodFilterData[];
};

type FilterDialogMoleculeProps = DialogMoleculeProps;

const FilterDialogMolecule: React.FC<FilterDialogMoleculeProps> = ({
  className,
  open,
  handleClose,
}) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);
  const {
    availableFilterData,
    appliedFilters,
    isAppliedFiltersLoading,
    applyNewFilters,
  } = useCvFiltering();

  const prepareFilterScheme = (filterData: FilterData[]) => {
    const newFilterScheme: FilterScheme = {
      activityType: [],
      positionSpecialization: [],
      activityPosition: [],
      activityPeriod: [],
    };

    filterData.forEach((data) => {
      if (data.type === ACTIVITY_TYPE_FILTER) {
        newFilterScheme.activityType.push(data);
      }
      if (data.type === ACTIVITY_POSITION_FILTER) {
        newFilterScheme.activityPosition.push(data);
      }
      if (data.type === POSITION_SPECIALIZATION_FILTER) {
        newFilterScheme.positionSpecialization.push(data);
      }
      if (data.type === ACTIVITY_PERIOD_FILTER) {
        newFilterScheme.activityPeriod.push(data);
      }
    });

    return newFilterScheme;
  };

  const [filterScheme, setFilterScheme] = React.useState<FilterScheme>(
    prepareFilterScheme(appliedFilters),
  );

  const availableFilterDataScheme = useMemo(
    () => prepareFilterScheme(availableFilterData),
    [availableFilterData],
  );

  useEffect(() => {
    setFilterScheme(prepareFilterScheme(appliedFilters));
  }, [appliedFilters]);

  const applyAndClose = () => {
    applyNewFilters([
      ...filterScheme.activityType,
      ...filterScheme.activityPosition,
      ...filterScheme.positionSpecialization,
      ...filterScheme.activityPeriod,
    ]);
    handleClose();
  };

  const cancelAndClose = () => {
    setFilterScheme(prepareFilterScheme(appliedFilters));
    handleClose();
  };

  const resetFilters = () => {
    setFilterScheme({
      activityType: [],
      activityPosition: [],
      positionSpecialization: [],
      activityPeriod: [],
    });
  };

  const onChangeActivityTypeFilter = (filterData: ActivityTypeFilterData[]) => {
    setFilterScheme({
      ...filterScheme,
      activityType: filterData,
    });
  };

  const onChangeActivityPositionFilter = (
    filterData: ActivityPositionFilterData[],
  ) => {
    setFilterScheme({
      ...filterScheme,
      activityPosition: filterData,
    });
  };

  const onChangeActivitySpecializationFilter = (
    filterData: PositionSpecializationFilterData[],
  ) => {
    setFilterScheme({
      ...filterScheme,
      positionSpecialization: filterData,
    });
  };

  const onChangeActivityPeriodFilter = (
    filterData: ActivityPeriodFilterData[],
  ) => {
    setFilterScheme({
      ...filterScheme,
      activityPeriod: filterData,
    });
  };

  const [isActivityTypeAccordionExpanded, setIsActivityTypeAccordionExpanded] =
    React.useState<boolean>(filterScheme.activityType.length > 0);

  const renderActivityTypeAccordion = () => (
    <ActivityTypeFilterDialogMoleculeAccordion
      className='filter-dialog__activity-type-accordion'
      isExpanded={isActivityTypeAccordionExpanded}
      initialFilterData={availableFilterDataScheme.activityType}
      currentFilterData={filterScheme.activityType}
      onChange={onChangeActivityTypeFilter}
      onChangeExpand={() =>
        setIsActivityTypeAccordionExpanded(!isActivityTypeAccordionExpanded)
      }
    />
  );

  const [
    isActivityPositionAccordionExpanded,
    setIsActivityPositionAccordionExpanded,
  ] = React.useState<boolean>(filterScheme.activityPosition.length > 0);

  const renderActivityPositionAccordion = () => (
    <ActivityPositionFilterDialogMoleculeAccordion
      className='activity-position-accordion'
      isExpanded={isActivityPositionAccordionExpanded}
      initialFilterData={availableFilterDataScheme.activityPosition}
      currentFilterData={filterScheme.activityPosition}
      onChange={onChangeActivityPositionFilter}
      onChangeExpand={() =>
        setIsActivityPositionAccordionExpanded(
          !isActivityPositionAccordionExpanded,
        )
      }
    />
  );

  const [
    isPositionSpecializationAccordionExpanded,
    setIsPositionSpecializationAccordionExpanded,
  ] = React.useState<boolean>(filterScheme.activityPosition.length > 0);

  const renderPositionSpecializationAccordion = () => (
    <PositionSpecializationFilterDialogMoleculeAccordion
      className='filter-dialog__position-specialization-accordion'
      isExpanded={isPositionSpecializationAccordionExpanded}
      initialFilterData={availableFilterDataScheme.positionSpecialization}
      currentFilterData={filterScheme.positionSpecialization}
      onChange={onChangeActivitySpecializationFilter}
      onChangeExpand={() =>
        setIsPositionSpecializationAccordionExpanded(
          !isPositionSpecializationAccordionExpanded,
        )
      }
    />
  );

  const [
    isActivityPeriodAccordionExpanded,
    setIsActivityPeriodAccordionExpanded,
  ] = React.useState<boolean>(filterScheme.activityPeriod.length > 0);

  const renderActivityPeriodAccordion = () => (
    <ActivityPeriodFilterDialogMoleculeAccordion
      className='activity-pediod-accordion'
      isExpanded={isActivityPeriodAccordionExpanded}
      initialFilterData={availableFilterDataScheme.activityPeriod}
      currentFilterData={filterScheme.activityPeriod}
      onChange={onChangeActivityPeriodFilter}
      onChangeExpand={() =>
        setIsActivityPeriodAccordionExpanded(!isActivityPeriodAccordionExpanded)
      }
    />
  );

  const renderDialogContent = () => (
    <>
      {renderActivityTypeAccordion()}
      {renderActivityPositionAccordion()}
      {renderPositionSpecializationAccordion()}
      {renderActivityPeriodAccordion()}
    </>
  );

  return (
    <Dialog
      className={`${className} filter-dialog`}
      open={open}
      onClose={cancelAndClose}
      fullWidth
      maxWidth='sm'
      scroll='paper'
      PaperProps={{ sx: { borderRadius: 5 } }}>
      <DialogTitle>{t(DIALOG_FILTER_TITLE_MAPPING)}</DialogTitle>
      <DialogContent
        sx={{
          '& .filter-dialog-accordion': {
            '&:first-of-type': {
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
            },
            '&:last-of-type': {
              borderBottomLeftRadius: '20px',
              borderBottomRightRadius: '20px',
            },
          },
        }}>
        {renderDialogContent()}
      </DialogContent>
      <DialogActions sx={{ paddingRight: 3 }}>
        <ButtonAtom
          type='reset'
          disabled={isAppliedFiltersLoading}
          onClick={resetFilters}
          color='error'
          sx={{ borderRadius: 4 }}>
          {t(DIALOG_FILTER_RESET_ALL_MAPPING)}
        </ButtonAtom>
        <ButtonAtom
          disabled={isAppliedFiltersLoading}
          onClick={cancelAndClose}
          sx={{ borderRadius: 4 }}>
          {t(DIALOG_FILTER_CLOSE_MAPPING)}
        </ButtonAtom>
        <ButtonAtom
          type='submit'
          disabled={isAppliedFiltersLoading}
          onClick={applyAndClose}
          color='success'
          sx={{ borderRadius: 4 }}>
          {t(DIALOG_FILTER_APPLY_MAPPING)}
        </ButtonAtom>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialogMolecule;
