import React from 'react';

import { Switch, SwitchProps } from '@mui/material';

type IconToggleAtomProps = SwitchProps;

const IconToggleAtom: React.FC<IconToggleAtomProps> = ({
  className,
  checked,
  icon,
  checkedIcon,
  onChange,
}) => {
  const customSx = {
    '& .MuiSwitch-switchBase': {
      margin: '2px',
      padding: '3px',
      color: 'primary.main',
      '& .MuiSvgIcon-root': {
        width: '28px',
        height: '28px',
      },
      '&.Mui-checked': {
        color: 'primary.main',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: 'primary.ghost',
        },
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: 'primary.ghost',
      transition: 'none',
    },
  };

  return (
    <Switch
      className={className}
      checked={checked}
      icon={icon}
      checkedIcon={checkedIcon}
      inputProps={{ 'aria-label': 'controlled' }}
      sx={customSx}
      onChange={onChange}
    />
  );
};

export default IconToggleAtom;
