import ConstructedCvUser from '../ConstructedCvUser';
import CvUserPositionId from '../fields/CvUserPositionId';
import PositionsResource from '../../../../../../../tools/resources/types/business/positions/PositionsResource';

import createConstructedUserPositionFromResource from '../../fields/position/constructed/operations/createConstructedUserPositionFromResource';

const updateConstructedUserWithPositionsResource = (
  constructedUser: ConstructedCvUser,
  positionsResource: PositionsResource,
): ConstructedCvUser => ({
  ...constructedUser,
  position: createConstructedUserPositionFromResource(
    positionsResource.find(
      (position) =>
        position.id === (constructedUser?.position as CvUserPositionId),
    ),
  ),
});

export default updateConstructedUserWithPositionsResource;
