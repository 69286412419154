import ConstructedCvUserPosition from '../ConstructedCvUserPosition';
import CvUserPositionSpecializationId from '../fields/CvUserPositionSpecializationId';
import CvUserPosition from '../../CvUserPosition';

import isPosition from '../../../../../../../position/guards/isPosition';

const toCvUserPosition = (
  position?: CvUserPositionSpecializationId | ConstructedCvUserPosition,
): CvUserPosition | undefined => {
  if (isPosition(position)) {
    return position as CvUserPosition;
  }

  return undefined;
};

export default toCvUserPosition;
