import FileResource from './object/FileResource';
import RESOURCES_DIRECTORIES from './resourcesDirectories';
import {
  JSON_RESOURCE,
  TEXT_RESOURCE,
} from './object/fields/extension/FileResourceExtension';

type Resources = {
  version: FileResource;
  languageBundles: FileResource;
  cv: FileResource;
  user: FileResource;
  activities: FileResource;
  subactivities: FileResource;
  projects: FileResource;
  providers: FileResource;
  positions: FileResource;
  skills: FileResource;
  tags: FileResource;
  specializations: FileResource;
};

const RESOURCES: Resources = {
  version: {
    type: TEXT_RESOURCE,
    description: 'current ICV version',
    name: 'version.txt',
    parent: RESOURCES_DIRECTORIES.root,
  },
  languageBundles: {
    type: JSON_RESOURCE,
    description: 'resources language bundles configuration file',
    name: 'languageBundles.json',
    parent: RESOURCES_DIRECTORIES.resources,
  },
  cv: {
    type: JSON_RESOURCE,
    description: 'CV',
    name: 'cv.json',
    parent: RESOURCES_DIRECTORIES.locale,
  },
  user: {
    type: JSON_RESOURCE,
    description: 'CV user',
    name: 'user.json',
    parent: RESOURCES_DIRECTORIES.locale,
  },
  activities: {
    type: JSON_RESOURCE,
    description: 'CV activities',
    name: 'activities.json',
    parent: RESOURCES_DIRECTORIES.locale,
  },
  subactivities: {
    type: JSON_RESOURCE,
    description: 'CV subactivities',
    name: 'subactivities.json',
    parent: RESOURCES_DIRECTORIES.locale,
  },
  projects: {
    type: JSON_RESOURCE,
    description: 'CV activity projects',
    name: 'projects.json',
    parent: RESOURCES_DIRECTORIES.locale,
  },
  providers: {
    type: JSON_RESOURCE,
    description: 'CV activity providers',
    name: 'providers.json',
    parent: RESOURCES_DIRECTORIES.locale,
  },
  positions: {
    type: JSON_RESOURCE,
    description: 'CV activity positions',
    name: 'positions.json',
    parent: RESOURCES_DIRECTORIES.locale,
  },
  skills: {
    type: JSON_RESOURCE,
    description: 'CV activity position skills',
    name: 'skills.json',
    parent: RESOURCES_DIRECTORIES.locale,
  },
  tags: {
    type: JSON_RESOURCE,
    description: 'CV tags',
    name: 'tags.json',
    parent: RESOURCES_DIRECTORIES.locale,
  },
  specializations: {
    type: JSON_RESOURCE,
    description: 'CV positions specialization',
    name: 'specializations.json',
    parent: RESOURCES_DIRECTORIES.locale,
  },
};

export default RESOURCES;
