import setValue, { SetValue } from '../../../setValue';

import Constructed from '../../../../../tools/constructed/Constructed';
import ConstructedCvUser from '../../../../../types/business/cv/fields/user/constructed/ConstructedCvUser';
import CvUserConstructFlow from '../../../../../types/business/cv/fields/user/constructed/CvUserConstructFlow';

import { SET_INITIAL_CV_USER } from '../../cvActionTypes';

export type SetInitialCvUser = SetValue<
  typeof SET_INITIAL_CV_USER,
  Constructed<CvUserConstructFlow, ConstructedCvUser>
>;

const setInitialCvUser = (
  cvUser: Constructed<CvUserConstructFlow, ConstructedCvUser>,
) => setValue(SET_INITIAL_CV_USER, cvUser);

export default setInitialCvUser;
