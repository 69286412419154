import ConstructedCvUser from '../ConstructedCvUser';
import SpecializationsResource from '../../../../../../../tools/resources/types/business/specializations/SpecializationsResource';
import ConstructedCvUserPosition from '../../fields/position/constructed/ConstructedCvUserPosition';

import updateConstructedUserPositionWithSpecializationsResource from '../../fields/position/constructed/operations/updateConstructedUserPositionWithSpecializationsResource';

const updateConstructedUserWithSpecializationsResource = (
  constructedUser: ConstructedCvUser,
  specializationsResource: SpecializationsResource,
): ConstructedCvUser => ({
  ...constructedUser,
  position: updateConstructedUserPositionWithSpecializationsResource(
    constructedUser.position as ConstructedCvUserPosition,
    specializationsResource,
  ),
});

export default updateConstructedUserWithSpecializationsResource;
