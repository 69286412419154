import AppThemeMode, {
  LIGHT_THEME_MODE,
} from '../../../types/app/theme/AppThemeMode';
import AppPalette from '../../../types/app/theme/palette/AppPalette';

export const createAppPalette = (
  mode: AppThemeMode,
): { palette: AppPalette } => ({
  palette: {
    mode,
    ...(mode === LIGHT_THEME_MODE
      ? {
          // Light theme
          primary: {
            main: '#1976d2',
            light: '#42a5f5',
            dark: '#1565c0',
            contrastText: '#fff',
            // Custom color requires all default colors above
            // CUSTOM
            ghost: '#b3cfea',
            // CUSTOM
            phantom: '#d4e2ef',
          },
          secondary: {
            main: '#e24329',
          },
          background: {
            default: '#f5f5f5',
            paper: '#fff',
            board: '#ddd',
          },
          error: {
            main: '#d32f2f',
            light: '#ef5350',
            // Changed from #c62828
            dark: '#b62424',
            contrastText: '#fff',
            // Custom color requires all default colors above
            // CUSTOM: Light theme, error.dark alpha 20%
            phantom: 'rgba(198,40,40,0.2)',
          },
        }
      : {
          // Dark theme
          primary: {
            main: '#90caf9',
            light: '#e3f2fd',
            dark: '#42a5f5',
            // Custom color requires all default colors above
            // CUSTOM
            ghost: '#384a58',
            // CUSTOM
            phantom: '#242d34',
          },
          secondary: {
            main: '#fc6d26',
          },
          background: {
            default: '#121212',
            paper: '#1e1e1e',
            board: '#2a2a2a',
          },
          error: {
            main: '#f44336',
            light: '#e57373',
            dark: '#d32f2f',
            contrastText: '#fff',
            // Custom color requires all default colors above
            // CUSTOM: Dark theme, error.dark alpha 25%
            phantom: 'rgba(211,47,47,0.25)',
          },
        }),
  } as AppPalette,
});
