import moment from 'moment';

import Duration from '../Duration';

const fromMomentDurationToDuration = (
  momentDuration: moment.Duration,
): Duration => ({
  years: momentDuration.years(),
  months: momentDuration.months(),
  days: momentDuration.days(),
});

export default fromMomentDurationToDuration;
