import { useCallback } from 'react';

interface UseWindowActions {
  scrollToTopSmoothly: () => void;
}

export const useWindowActions = (): UseWindowActions => {
  const scrollToTopSmoothly = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return { scrollToTopSmoothly };
};

export default useWindowActions;
