import useI18n from './useI18n';

import Date from '../../tools/timedate/date/Date';
import FixedDateFormat from '../../tools/timedate/date/formats/FixedDateFormat';
import DateFormat from '../../tools/timedate/date/formats/DateFormat';
import Duration from '../../tools/timedate/duration/Duration';
import HumanizeDurationFormats, {
  Y_MO_D_DURATION_FORMAT,
} from '../../tools/internationalization/humanizeDuration/HumanizeDurationFormats';
import PresentDateFormat, {
  CAPITAL_PRESENT_DATE_FORMAT,
  LOWERCASE_PRESENT_DATE_FORMAT,
} from '../../tools/timedate/date/formats/PresentDateFormat';
import isPresentDate from '../../tools/timedate/date/guards/isPresentDate';

import { TIMEDATE_LOCALE_NAMESPACE } from '../../tools/internationalization/i18n/localization/namespaces/timedate/TimedateLocaleNamespace';
import { ll_LOCALE_DATE_FORMAT } from '../../tools/timedate/date/formats/LocaleDateFormat';
import { DATES_PRESENT_MAPPING } from '../../tools/internationalization/i18n/localization/namespaces/timedate/TimedateLocaleNamespaceMappings';

interface UseTimeDateInternationalization {
  /**
   * Get internationalized and formatted date according to the current locale.
   * @param date Datestamp with provided date.
   * @param format Target locale date format.
   * @param presentFormat Target present word date format.
   */
  dai(
    date: Date,
    format?: DateFormat,
    presentFormat?: PresentDateFormat,
  ): string;

  /**
   * Get internationalized and formatted period according to the current locale.
   * @param duration Duration with provided period.
   * @param format Target duration format.
   */
  dui(duration: Duration, format?: HumanizeDurationFormats): string;

  /**
   * Current locale fixed date format.
   */
  fdf: FixedDateFormat;
}

const useTimeDateI18n = (): UseTimeDateInternationalization => {
  const { t, daf, duf, fdf } = useI18n(TIMEDATE_LOCALE_NAMESPACE);

  const dateInternationalize = (
    date: Date,
    format: DateFormat = ll_LOCALE_DATE_FORMAT,
    presentFormat: PresentDateFormat = CAPITAL_PRESENT_DATE_FORMAT,
  ) => {
    if (isPresentDate(date)) {
      const presentInternationalized = t(DATES_PRESENT_MAPPING);
      return presentFormat === LOWERCASE_PRESENT_DATE_FORMAT
        ? presentInternationalized.toLowerCase()
        : presentInternationalized;
    }
    return daf(date, format);
  };

  const durationInternationalize = (
    duration: Duration,
    format: HumanizeDurationFormats = Y_MO_D_DURATION_FORMAT,
  ) => duf(duration, format);

  return {
    dai: dateInternationalize,
    dui: durationInternationalize,
    fdf,
  };
};

export default useTimeDateI18n;
