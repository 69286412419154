import requireResource, { RequireResource } from '../requireResource';
import { LOAD_SPECIALIZATIONS_RESOURCE_REQUIREMENT } from '../resourceActionTypes';

export type RequireSpecializationsResource = RequireResource<
  typeof LOAD_SPECIALIZATIONS_RESOURCE_REQUIREMENT
>;

const requireSpecializationsResource = () =>
  requireResource(LOAD_SPECIALIZATIONS_RESOURCE_REQUIREMENT);

export default requireSpecializationsResource;
