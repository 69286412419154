import DemandingConstructStage from '../../../../../../../tools/constructed/stages/DemandingConstructStage';
import {
  CV_USER_BASE_BUILD_STAGE_NAME,
  CV_USER_POSITIONS_BUILD_STAGE_NAME,
} from '../CvUserConstructStageName';

type CvUserPositionsConstructStage = DemandingConstructStage<
  typeof CV_USER_POSITIONS_BUILD_STAGE_NAME,
  typeof CV_USER_BASE_BUILD_STAGE_NAME
>;

export const CV_USER_POSITIONS_CONSTRUCT_STAGE: CvUserPositionsConstructStage =
  {
    name: CV_USER_POSITIONS_BUILD_STAGE_NAME,
    require: [CV_USER_BASE_BUILD_STAGE_NAME],
  };
