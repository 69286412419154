import ConstructedCvActivity from '../ConstructedCvActivity';
import PositionsResource from '../../../../../../../tools/resources/types/business/positions/PositionsResource';
import CvActivityPositionId from '../fields/CvActivityPositionId';

import createConstructedActivityPositionFromResource from '../../fields/position/constructed/operations/createConstructedActivityPositionFromResource';

const updateConstructedActivityWithPositionResource = (
  constructedActivity: ConstructedCvActivity,
  positionsResource: PositionsResource,
): ConstructedCvActivity => ({
  ...constructedActivity,
  position: createConstructedActivityPositionFromResource(
    positionsResource.find(
      (position) =>
        position.id === (constructedActivity.position as CvActivityPositionId),
    ),
  ),
});

export default updateConstructedActivityWithPositionResource;
