import setValue, { SetValue } from '../../../setValue';

import FilterData from '../../../../../types/business/filter/data/FilterData';
import LoadableValue from '../../../../modifiers/LoadableValue';

import { SET_AVAILABLE_FILTERS } from '../../cvActionTypes';

export type SetAvailableFilters = SetValue<
  typeof SET_AVAILABLE_FILTERS,
  LoadableValue<FilterData[]>
>;

const setAvailableFilters = (filterData: LoadableValue<FilterData[]>) =>
  setValue(SET_AVAILABLE_FILTERS, filterData);

export default setAvailableFilters;
