import React, { useEffect } from 'react';

import { AccordionDetails, Box } from '@mui/material';

import useI18n from '../../../../../../../hooks/internationalization/useI18n';

import Specialization from '../../../../../../../types/business/specialization/Specialization';
import PositionSpecializationFilterData from '../../../../../../../types/business/filter/data/variants/specialization/PositionSpecializationFilterData';

import FilterDialogMoleculeAccordionProps from '../FilterDialogMoleculeAccordionProps';
import CheckboxAtom from '../../../../../atoms/checkboxes/CheckboxAtom';
import CheckboxGroupAtom from '../../../../../atoms/checkboxes/group/CheckboxGroupAtom';
import BaseFilterDialogMoleculeAccordion from '../base/BaseFilterDialogMoleculeAccordion';

import { POSITION_SPECIALIZATION_FILTER } from '../../../../../../../types/business/filter/data/prototype/fields/FilterType';
import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { DIALOG_FILTER_ACCORDION_POSITION_SPECIALIZATION_TITLE_MAPPING } from '../../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type ActivitySpecializationFilterDialogMoleculeAccordionProps =
  FilterDialogMoleculeAccordionProps<PositionSpecializationFilterData>;

const PositionSpecializationFilterDialogMoleculeAccordion: React.FC<
  ActivitySpecializationFilterDialogMoleculeAccordionProps
> = ({
  className,
  isExpanded,
  initialFilterData,
  currentFilterData,
  onChange,
  onChangeExpand,
}) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);

  const [accordionFilterData, setAccordionFilterData] = React.useState<
    PositionSpecializationFilterData[]
  >([]);

  useEffect(() => {
    setAccordionFilterData(currentFilterData);
  }, [currentFilterData]);

  const resetFilter = () => {
    setAccordionFilterData([]);
    onChange([]);
  };

  const handleClick = (specialization: Specialization) => {
    const newFilterData = [...accordionFilterData];

    const filterDataSpecialization = newFilterData.findIndex(
      (filterData) => filterData.data.id === specialization.id,
    );
    if (filterDataSpecialization === -1) {
      newFilterData.push({
        type: POSITION_SPECIALIZATION_FILTER,
        data: specialization,
      });
    } else {
      newFilterData.splice(filterDataSpecialization, 1);
    }

    setAccordionFilterData(newFilterData);
    onChange(newFilterData);
  };

  const renderCheckbox = (specialization: Specialization) => (
    <Box
      className={`${specialization.name}-position-specialization-checkbox__wrapper`}
      key={specialization.name}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleClick(specialization);
      }}>
      <CheckboxAtom
        className={`${specialization.name}-position-specialization-checkbox`}
        name={specialization.id}
        checked={accordionFilterData.every(
          (filterData) => filterData.data.id !== specialization.id,
        )}
        label={specialization.name}
      />
    </Box>
  );

  const renderAvailableCheckboxes = () =>
    initialFilterData.map((filterData) => renderCheckbox(filterData.data));

  return (
    <BaseFilterDialogMoleculeAccordion
      className={`${className} position-specialization-accordion`}
      id='activity-specialization'
      isExpanded={isExpanded}
      title={t(DIALOG_FILTER_ACCORDION_POSITION_SPECIALIZATION_TITLE_MAPPING)}
      data={currentFilterData}
      onChangeExpand={onChangeExpand}
      onReset={resetFilter}>
      <AccordionDetails className='position-specialization-accordion__details'>
        <CheckboxGroupAtom className='details__group' row>
          {renderAvailableCheckboxes()}
        </CheckboxGroupAtom>
      </AccordionDetails>
    </BaseFilterDialogMoleculeAccordion>
  );
};

export default PositionSpecializationFilterDialogMoleculeAccordion;
