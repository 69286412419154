import React from 'react';

import { Stack } from '@mui/material';

import useAssetsResources from '../../../../../hooks/resources/useAssetsResources';
import useWindowBreakpoint from '../../../../../hooks/layout/window/useWindowBreakpoint';
import useI18n from '../../../../../hooks/internationalization/useI18n';
import useInternalNavigation from '../../../../../hooks/navigation/useInternalNavigation';
import useExternalNavigation from '../../../../../hooks/navigation/useExternalNavigation';

import { ComponentProps } from '../../../ComponentProps';
import LinkAtom from '../../../atoms/links/LinkAtom';
import TypographyAtom from '../../../atoms/typography/TypographyAtom';
import PaperBackgroundMolecule from '../../../molecules/background/paper/PaperBackgroundMolecule';
import NavListMolecule from '../../../molecules/lists/nav/NavListMolecule';

import {
  FOOTER_COPYRIGHT_MAPPING,
  FOOTER_CREATED_BY_COMPACT_MAPPING,
  FOOTER_CREATED_CREATOR_NAME_MAPPING,
} from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespaceMappings';
import { ORGANISMS_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/organisms/OrganismsLocaleNamespace';

type CompactFooterOrganismProps = ComponentProps;

export const CompactFooterOrganism: React.FC<CompactFooterOrganismProps> = ({
  className = 'compact-footer-organism',
}) => {
  const { gitLabLogo } = useAssetsResources();
  const breakpoint = useWindowBreakpoint();
  const { t: tO } = useI18n(ORGANISMS_LOCALE_NAMESPACE);

  const { toActivities } = useInternalNavigation();
  const { toCreatorsWebsite, toIcvGitLabRepo } = useExternalNavigation();

  const footerContentStyle = () =>
    breakpoint === 'xs'
      ? {
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }
      : {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        };

  const renderAbout = () => (
    <Stack
      className='footer__about'
      direction='row'
      alignItems='center'
      spacing={0.5}
      paddingTop={0.5}
      paddingLeft={{ xs: 0, sm: 1 }}
      useFlexGap
      flexWrap='wrap'>
      <LinkAtom
        className='footer__about-copyright'
        underline='none'
        color='inherit'
        autoReplace
        to={toActivities}>
        {tO(FOOTER_COPYRIGHT_MAPPING)}
      </LinkAtom>
      <TypographyAtom className='footer__about-created-by'>
        {tO(FOOTER_CREATED_BY_COMPACT_MAPPING)}
      </TypographyAtom>
      <LinkAtom
        className='footer__about-creator'
        underline='none'
        color='inherit'
        to={toCreatorsWebsite()}>
        {tO(FOOTER_CREATED_CREATOR_NAME_MAPPING)}
      </LinkAtom>
    </Stack>
  );

  const renderSocialList = () => (
    <NavListMolecule
      className='footer__social-list'
      navs={[
        {
          image: gitLabLogo,
          to: toIcvGitLabRepo(),
        },
      ]}
      size='small'
    />
  );

  return (
    <PaperBackgroundMolecule
      className={`${className} footer`}
      component='footer'
      square
      sx={{
        width: '100%',
        paddingY: 2,
        paddingX: '3%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Stack
        className='footer__content'
        width='100%'
        maxWidth='900px'
        spacing={{ xs: 1, sm: 0 }}
        sx={footerContentStyle()}>
        {renderAbout()}
        {renderSocialList()}
      </Stack>
    </PaperBackgroundMolecule>
  );
};

export default CompactFooterOrganism;
