import setValue, { SetValue } from '../../../setValue';

import AppTabKey from '../../../../../types/app/tabs/key/AppTabKey';
import AppTabScroll from '../../../../../types/app/tabs/state/fields/scroll/AppTabScroll';

import { SET_APP_TAB_SCROLL } from '../../appActionTypes';

type AppTabScrollPayload = {
  key: AppTabKey;
  value: AppTabScroll;
};

export type SetAppTabScroll = SetValue<
  typeof SET_APP_TAB_SCROLL,
  AppTabScrollPayload
>;

export const setAppTabScroll = (tabKey: AppTabKey, scroll: AppTabScroll) =>
  setValue(SET_APP_TAB_SCROLL, { key: tabKey, value: scroll });

export default setAppTabScroll;
