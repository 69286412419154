import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setAppTabScroll from '../../../storage/actions/app/set/scroll/setAppTabScroll';

import AppTabKey from '../../../types/app/tabs/key/AppTabKey';

interface UseAppTabsScroll {
  saveScroll(tabKey: AppTabKey): void;

  restoreScroll(tabKey: AppTabKey): void;
}

const useAppTabsScroll = (): UseAppTabsScroll => {
  const dispatch = useRootDispatch();
  const { tabs } = useSelector((state: RootState) => state.appReducer);

  const saveScroll = useCallback(
    (tabKey: AppTabKey) => {
      dispatch(setAppTabScroll(tabKey, window.scrollY));
    },
    [dispatch],
  );

  const restoreScroll = (tabKey: AppTabKey) => {
    window.scrollTo(0, tabs[tabKey].scroll);
  };

  return {
    saveScroll,
    restoreScroll,
  };
};

export default useAppTabsScroll;
