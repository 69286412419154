import React from 'react';

import useI18n from '../../../../../hooks/internationalization/useI18n';

import UserContact from '../../../../../types/business/user/UserContact';
import ContactsUserPreference, {
  ACTIVE_CONTACTS_USER_PREFERENCE,
  LIMITED_CONTACTS_USER_PREFERENCE,
  REGULAR_CONTACTS_USER_PREFERENCE,
} from '../../../../../types/business/user/preferences/ContactsUserPreference';

import { ComponentProps } from '../../../ComponentProps';
import ContactDialogMolecule from '../../dialogs/contact/ContactDialogMolecule';
import ButtonAtom from '../../../atoms/buttons/regular/ButtonAtom';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { BUTTON_CONTACT_TEXT_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type ContactButtonMoleculeProps = ComponentProps & {
  contacts: UserContact[];
  preference?: ContactsUserPreference;
};

const ContactButtonMolecule: React.FC<ContactButtonMoleculeProps> = ({
  className,
  contacts,
  preference,
}) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);

  const [isDialogOpened, setIsDialogOpened] = React.useState(false);

  const determineButtonColorAccordingToPreference = () => {
    switch (preference) {
      case ACTIVE_CONTACTS_USER_PREFERENCE:
        return 'success';
      case LIMITED_CONTACTS_USER_PREFERENCE:
        return 'warning';
      case REGULAR_CONTACTS_USER_PREFERENCE:
      default:
        return 'primary';
    }
  };

  return (
    <>
      <ButtonAtom
        className={`${className} contacts-button`}
        variant='contained'
        color={determineButtonColorAccordingToPreference()}
        onClick={() => setIsDialogOpened(true)}
        sx={{ borderRadius: 5 }}>
        {t(BUTTON_CONTACT_TEXT_MAPPING)}
      </ButtonAtom>
      <ContactDialogMolecule
        className='contacts-dialog'
        contacts={contacts}
        preference={preference}
        open={isDialogOpened}
        handleClose={() => setIsDialogOpened(false)}
      />
    </>
  );
};

export default ContactButtonMolecule;
