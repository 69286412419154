import TagGroupName from './TagGroupName';
import Tag from '../Tag';

export const DEFAULT_TAG_GROUP_NAME = 'DEFAULT';

export const DEFAULT_TAG_GROUP: TagGroup = {
  name: DEFAULT_TAG_GROUP_NAME,
  tags: [],
};

type TagGroup = {
  name?: TagGroupName;
  tags: Tag[];
};

export default TagGroup;
