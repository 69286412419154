import TagGroup, {
  DEFAULT_TAG_GROUP,
} from '../../../../types/business/tag/group/TagGroup';
import Experience from '../../../../types/business/experience/Experience';
import ExperienceGroup from '../../../../types/business/experience/group/ExperienceGroup';
import SkillId from '../../../../types/business/skill/SkillId';
import SkillTag from '../../../../types/business/skill/SkillTag';
import { DEFAULT_EXPERIENCE_GROUP_ID } from '../../../../types/business/experience/group/ExperienceGroupId';

const findMaxTagGroupLength = (tagGroups: TagGroup[]): number => {
  let maxTagGroupLength = 0;

  tagGroups.forEach((tagGroup) => {
    if (tagGroup.tags.length > maxTagGroupLength) {
      maxTagGroupLength = tagGroup.tags.length;
    }
  });

  return maxTagGroupLength;
};

const createExperiencesSourceMap = (
  experiences: Experience[],
): Map<SkillId, Experience> => {
  const experienceSourceMap = new Map<SkillId, Experience>();

  experiences.forEach((experience) => {
    experienceSourceMap.set(experience.id, experience);
  });

  return experienceSourceMap;
};

const initializeExperienceGroups = (
  tagGroups: TagGroup[],
): ExperienceGroup[] => {
  const experienceGroups: ExperienceGroup[] = [];
  tagGroups.forEach((tagGroup, index) => {
    experienceGroups.push({
      id: index,
      tagGroup,
      experiences: [],
    });
  });

  return experienceGroups;
};

const retrieveExperiencesForProvidedTags = (
  skillTags: SkillTag[],
  experienceSourceMap: Map<SkillId, Experience>,
): Experience[] => {
  const experienceIdsToRetrieve = new Set<SkillId>();

  skillTags.forEach((skillTag) => {
    experienceSourceMap.forEach((experience) => {
      if (experience.tags) {
        experience.tags.forEach((tag) => {
          if (tag.id === skillTag.id) {
            experienceIdsToRetrieve.add(experience.id);
          }
        });
      }
    });
  });

  const retrievedExperiences: Experience[] = [];
  experienceIdsToRetrieve.forEach((experienceId) => {
    const retrievedExperience = experienceSourceMap.get(experienceId);
    if (retrievedExperience) {
      retrievedExperiences.push(retrievedExperience);
      experienceSourceMap.delete(retrievedExperience.id);
    }
  });

  return retrievedExperiences;
};

const createDefaultExperienceGroup = (
  experienceSourceMap: Map<SkillId, Experience>,
): ExperienceGroup => {
  const ungroupedExperiences: Experience[] = [];

  experienceSourceMap.forEach((experience) => {
    ungroupedExperiences.push(experience);
  });

  return {
    id: DEFAULT_EXPERIENCE_GROUP_ID,
    tagGroup: DEFAULT_TAG_GROUP,
    experiences: ungroupedExperiences,
  };
};

const groupSkillsExperienceByTagGroups = (
  tagGroups: TagGroup[],
  experiences: Experience[],
): ExperienceGroup[] => {
  const maxTagGroupLength = findMaxTagGroupLength(tagGroups);
  const experiencesSourceMap: Map<SkillId, Experience> =
    createExperiencesSourceMap(experiences);
  const experienceGroups = initializeExperienceGroups(tagGroups);

  for (
    let currentTagGroupLength = 1;
    currentTagGroupLength <= maxTagGroupLength;
    currentTagGroupLength++
  ) {
    experienceGroups.forEach((experienceGroup) => {
      const providedGroupTags = experienceGroup.tagGroup.tags.slice(
        0,
        currentTagGroupLength,
      );
      experienceGroup.experiences = [
        ...experienceGroup.experiences,
        ...retrieveExperiencesForProvidedTags(
          providedGroupTags,
          experiencesSourceMap,
        ),
      ];
    });
  }

  experienceGroups.push(createDefaultExperienceGroup(experiencesSourceMap));

  return experienceGroups;
};

export default groupSkillsExperienceByTagGroups;
