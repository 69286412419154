import React from 'react';

import useI18n from '../../../../../hooks/internationalization/useI18n';
import useDynamicResources from '../../../../../hooks/resources/useDynamicResources';

import { ComponentProps } from '../../../ComponentProps';
import ImageCommon from '../../../../../types/common/image/ImageCommon';
import AvatarAtom from '../../../atoms/avatars/AvatarAtom';

import { ASSETS_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/assets/AssetsLocaleNamespace';
import { AVATAR_PLACEHOLDER_ALT_MAPPING } from '../../../../../tools/internationalization/i18n/localization/namespaces/assets/AssetsLocaleNamespaceMappings';

type LoadableImageProps = ComponentProps & {
  image?: ImageCommon;
};

const AvatarImageMolecule: React.FC<LoadableImageProps> = ({
  className,
  image,
}) => {
  const { t } = useI18n(ASSETS_LOCALE_NAMESPACE);
  const { buildUrlFromImageResourceSource } = useDynamicResources();

  const renderAvatarStyle = () => ({
    width: '170px',
    height: '170px',
  });

  return image ? (
    <AvatarAtom
      className={`${className} avatar-image`}
      src={buildUrlFromImageResourceSource(image.src)}
      sx={renderAvatarStyle()}
      shape={image.shape}
    />
  ) : (
    <AvatarAtom
      className={`${className} avatar-image__placeholder`}
      placeholder
      alt={t(AVATAR_PLACEHOLDER_ALT_MAPPING)}
      sx={renderAvatarStyle()}
    />
  );
};

export default AvatarImageMolecule;
