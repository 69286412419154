import React from 'react';

import { Accordion, AccordionActions, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import useI18n from '../../../../../../../hooks/internationalization/useI18n';

import FilterData from '../../../../../../../types/business/filter/data/FilterData';

import { ComponentProps } from '../../../../../ComponentProps';
import BadgeAtom from '../../../../../atoms/badges/BadgeAtom';
import TypographyAtom from '../../../../../atoms/typography/TypographyAtom';
import ButtonAtom from '../../../../../atoms/buttons/regular/ButtonAtom';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { DIALOG_FILTER_ACCORDION_RESET_MAPPING } from '../../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type BaseFilterDialogMoleculeAccordionProps = ComponentProps & {
  id: string;
  isExpanded: boolean;
  title: string;
  data: FilterData[];
  onChangeExpand: () => void;
  onReset: () => void;
  children?: React.ReactNode;
};

const BaseFilterDialogMoleculeAccordion: React.FC<
  BaseFilterDialogMoleculeAccordionProps
> = ({
  className,
  id,
  isExpanded,
  title,
  data,
  onChangeExpand,
  onReset,
  children,
}) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);

  const renderAccordionSummary = () => (
    <AccordionSummary
      className={`${className}__summary`}
      expandIcon={<ExpandMore />}
      aria-controls={`${className}-content`}
      id={`${id}-header`}>
      <BadgeAtom
        className={`${className}__summary-filters-count-badge`}
        badgeContent={data.length}
        color='error'
        topIndentation='12px'
        leftIndentation='18px'>
        <TypographyAtom className={`${className}__summary-title`}>
          {title}
        </TypographyAtom>
      </BadgeAtom>
    </AccordionSummary>
  );

  const renderAccordionActions = () => (
    <AccordionActions className={`${className}__actions`}>
      <ButtonAtom
        className='actions__reset'
        type='reset'
        onClick={onReset}
        color='error'
        sx={{ borderRadius: 4 }}>
        {t(DIALOG_FILTER_ACCORDION_RESET_MAPPING)}
      </ButtonAtom>
    </AccordionActions>
  );

  return (
    <Accordion
      className={`${className} filter-dialog-accordion`}
      expanded={isExpanded}
      onChange={onChangeExpand}
      square>
      {renderAccordionSummary()}
      {children}
      {renderAccordionActions()}
    </Accordion>
  );
};

export default BaseFilterDialogMoleculeAccordion;
