import Position from '../Position';
import isObject from '../../../common/guards/isObject';
import isSpecialization from '../../specialization/guards/isSpecialization';

const isPosition = (position: unknown): position is Position =>
  isObject(position) &&
  (position as Position).id !== undefined &&
  (position as Position).name !== undefined &&
  isSpecialization((position as Position).specialization);

export default isPosition;
