import React, { ReactElement } from 'react';

import AppTabKey from '../../../../../types/app/tabs/key/AppTabKey';

import { ComponentProps } from '../../../ComponentProps';
import TabGroupAtom from '../../../atoms/tabs/group/TabGroupAtom';
import TabAtom from '../../../atoms/tabs/TabAtom';

type TabbedNavigationTabKey = AppTabKey;

type TabbedNavigationMoleculeOption = {
  key: TabbedNavigationTabKey;
  path: string;
  name: string;
  icon?: ReactElement;
};

type TabbedNavigationMoleculeProps = ComponentProps & {
  currentTab?: TabbedNavigationTabKey;
  tabs: TabbedNavigationMoleculeOption[];
  onChange?: (key: TabbedNavigationTabKey) => void;
  onTabClick?: (key: TabbedNavigationTabKey) => void;
};

const TabbedNavigationMolecule: React.FC<TabbedNavigationMoleculeProps> = ({
  className,
  currentTab,
  tabs,
  onChange,
  onTabClick,
}) => (
  <TabGroupAtom
    className={`${className} tabbed-navigation`}
    variant='fullWidth'
    scrollableBreakpoint='xs'
    allowScrollButtonsMobile
    hideScrollButtonsInEndPositions
    hideIndicator
    value={currentTab}
    sx={{ width: '100%' }}
    onChange={(_, value: TabbedNavigationTabKey) => {
      if (onChange) {
        onChange(value);
      }
    }}>
    {tabs.map(({ key, name, icon }) => (
      <TabAtom
        className={`tabbed-navigation__tab-${key} tabbed-navigation__tab`}
        key={key}
        value={key}
        label={name}
        icon={icon}
        iconPosition='start'
        onClick={onTabClick ? () => onTabClick(key) : undefined}
      />
    ))}
  </TabGroupAtom>
);

export default TabbedNavigationMolecule;
