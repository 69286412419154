import React from 'react';

import { Link } from '@mui/icons-material';

import useDynamicResources from '../../../../../hooks/resources/useDynamicResources';

import NavEndpoint from '../../../../../tools/navigation/endpoints/NavEndpoint';
import ImageCommon from '../../../../../types/common/image/ImageCommon';
import ImageCommonSource from '../../../../../types/common/image/fields/source/ImageCommonSource';

import { ComponentProps } from '../../../ComponentProps';
import AvatarAtom from '../../../atoms/avatars/AvatarAtom';
import LinkAtom from '../../../atoms/links/LinkAtom';
import BadgeAtom from '../../../atoms/badges/BadgeAtom';
import useAppTheme from '../../../../../hooks/layout/theme/useAppTheme';

type ImageCommonWithUndefinedSource = Omit<ImageCommon, 'src'> & {
  src?: ImageCommonSource;
};

type AvatarLinkMoleculeProps = ComponentProps & {
  image: ImageCommonWithUndefinedSource;
  to: NavEndpoint;
  size?: 'small' | 'medium';
  badge?: boolean;
};

type AvatarLinkSize = {
  avatarWidth: string;
  avatarHeight: string;
};

const AvatarLinkMolecule: React.FC<AvatarLinkMoleculeProps> = ({
  className,
  image,
  to,
  size = 'small',
  badge = false,
}) => {
  const { appTheme } = useAppTheme();
  const { buildUrlFromImageResourceSource } = useDynamicResources();

  const SMALL_AVATAR_LINK_SIZE: AvatarLinkSize = {
    avatarWidth: '24px',
    avatarHeight: '24px',
  };

  const MEDIUM_AVATAR_LINK_SIZE: AvatarLinkSize = {
    avatarWidth: '32px',
    avatarHeight: '32px',
  };

  const getAvatarLinkSize = (): AvatarLinkSize => {
    switch (size) {
      case 'medium':
        return MEDIUM_AVATAR_LINK_SIZE;
      case 'small':
      default:
        return SMALL_AVATAR_LINK_SIZE;
    }
  };

  const renderLinkAvatar = () => (
    <AvatarAtom
      className='avatar-link__image'
      src={image.src ? buildUrlFromImageResourceSource(image.src) : undefined}
      alt={image.alt}
      shape={image.shape}
      sx={{
        width: getAvatarLinkSize().avatarWidth,
        height: getAvatarLinkSize().avatarHeight,
      }}
    />
  );

  const renderLinkAvatarWithBadge = () => (
    <BadgeAtom
      className='avatar-link__badge'
      overlap='circular'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <AvatarAtom
          className='avatar-link__badge-image'
          shape='circular'
          children={
            <Link
              enableBackground='primary'
              style={{ width: '16px', height: '16px', rotate: '-45deg' }}
            />
          }
          sx={{
            width: '16px',
            height: '16px',
            bgcolor: appTheme.palette.primary.main,
          }}
        />
      }>
      {renderLinkAvatar()}
    </BadgeAtom>
  );

  return (
    <LinkAtom className={`${className} avatar-link`} to={to} underline='none'>
      {badge ? renderLinkAvatarWithBadge() : renderLinkAvatar()}
    </LinkAtom>
  );
};

export default AvatarLinkMolecule;
