import setValue, { SetValue } from '../../../setValue';

import Filter from '../../../../../types/business/filter/common/Filter';

import { SET_APPLIED_FILTERS } from '../../cvActionTypes';
import LoadableValue from '../../../../modifiers/LoadableValue';

export type SetAppliedFilters = SetValue<
  typeof SET_APPLIED_FILTERS,
  LoadableValue<Filter[]>
>;

const setAppliedFilters = (filters: LoadableValue<Filter[]>) =>
  setValue(SET_APPLIED_FILTERS, filters);

export default setAppliedFilters;
