import Specialization from '../../../../../../../specialization/Specialization';
import SpecializationResource from '../../../../../../../../../tools/resources/types/business/specializations/SpecializationResource';

type CvUserPositionSpecialization = Specialization;

export const createCvUserPositionSpecializationFromResource = (
  specializationResource: SpecializationResource,
): CvUserPositionSpecialization => ({
  id: specializationResource.id,
  name: specializationResource.name,
});

export default CvUserPositionSpecialization;
