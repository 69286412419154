import setValue, { SetValue } from '../../../setValue';

import AppThemeMode from '../../../../../types/app/theme/AppThemeMode';

import { SET_APP_THEME_MODE } from '../../appActionTypes';

export type SetAppThemeMode = SetValue<typeof SET_APP_THEME_MODE, AppThemeMode>;

const setAppThemeMode = (mode: AppThemeMode) =>
  setValue(SET_APP_THEME_MODE, mode);

export default setAppThemeMode;
