import React from 'react';

import useAppTheme from '../../../../../../hooks/layout/theme/useAppTheme';
import useWindowDimensions from '../../../../../../hooks/layout/window/useWindowDimensions';
import useTimeDateI18n from '../../../../../../hooks/internationalization/useTimeDateI18n';

import SkillName from '../../../../../../types/business/skill/SkillName';

import Experience from '../../../../../../types/business/experience/Experience';
import fromDurationToMomentDuration from '../../../../../../tools/timedate/duration/converters/fromDurationToMomentDuration';
import fromMomentDurationToDuration from '../../../../../../tools/timedate/duration/converters/fromMomentDurationToDuration';

import { ComponentProps } from '../../../../ComponentProps';
import BarChartAtom from '../../../../atoms/charts/bar/BarChartAtom';

const SKILLS_CHART_NAME_KEY = 'name';
const SKILLS_CHART_DATA_KEY = 'experienceInDays';

type SkillsDatasetEntry = {
  [SKILLS_CHART_NAME_KEY]: SkillName;
  [SKILLS_CHART_DATA_KEY]: number;
};

const CHART_BAR_HEIGHT = 45;
const CHART_NO_MARGIN = 0;
const CHART_TOP_MARGIN = 0;
const CHART_LEFT_MIN_MARGIN = 100;
const CHART_LEFT_MAX_MARGIN = 225;
const CHART_LEFT_MARGIN_DIVIDER = 4;
const CHART_RIGHT_MAX_MARGIN = 24;
const CHART_RIGHT_MARGIN_DIVIDER = 25;

type SkillsBarChartMoleculeProps = ComponentProps & {
  experience: Experience[];
};

const SkillsBarChartMolecule: React.FC<SkillsBarChartMoleculeProps> = ({
  className,
  experience,
}) => {
  const { appTheme } = useAppTheme();
  const { innerWidth: windowWidth } = useWindowDimensions();
  const { dui } = useTimeDateI18n();

  const transformExperienceToDataset = (
    totalExperience: Experience[],
  ): SkillsDatasetEntry[] =>
    totalExperience.map((skill) => ({
      [SKILLS_CHART_NAME_KEY]: skill.name,
      [SKILLS_CHART_DATA_KEY]: fromDurationToMomentDuration(
        skill.experience,
      ).asDays(),
    }));

  const experienceValueFormatter = (experienceInDays: number | null): string =>
    dui(
      fromMomentDurationToDuration(
        fromDurationToMomentDuration({
          years: 0,
          months: 0,
          days: experienceInDays ?? 0,
        }),
      ),
    );

  const calculateChartRightMargin = (width: number): number => {
    const calculatedMargin =
      width / CHART_RIGHT_MARGIN_DIVIDER - CHART_RIGHT_MAX_MARGIN;
    if (calculatedMargin <= 0) {
      return CHART_NO_MARGIN;
    }
    if (calculatedMargin > CHART_RIGHT_MAX_MARGIN) {
      return CHART_RIGHT_MAX_MARGIN;
    }
    return calculatedMargin;
  };

  const calculateChartLeftMargin = (width: number): number => {
    const calculatedMargin = width / CHART_LEFT_MARGIN_DIVIDER;
    if (calculatedMargin <= CHART_LEFT_MIN_MARGIN) {
      return CHART_LEFT_MIN_MARGIN;
    }
    if (calculatedMargin > CHART_LEFT_MAX_MARGIN) {
      return CHART_LEFT_MAX_MARGIN;
    }
    return calculatedMargin;
  };

  return (
    <BarChartAtom
      className={`${className} skills-bar-chart`}
      disableAxisListener
      dataset={transformExperienceToDataset(experience)}
      yAxis={[
        {
          scaleType: 'band',
          tickPlacement: 'start',
          dataKey: SKILLS_CHART_NAME_KEY,
          colorMap: {
            type: 'ordinal',
            colors: [appTheme.palette.primary.dark as string],
          },
        },
      ]}
      firstTickHidden
      xAxisHidden
      series={[
        {
          dataKey: SKILLS_CHART_DATA_KEY,
          valueFormatter: experienceValueFormatter,
        },
      ]}
      layout='horizontal'
      height={experience.length * CHART_BAR_HEIGHT + CHART_TOP_MARGIN}
      margin={{
        top: CHART_TOP_MARGIN,
        right: calculateChartRightMargin(windowWidth),
        bottom: CHART_NO_MARGIN,
        left: calculateChartLeftMargin(windowWidth),
      }}
      borderRadius={8}
    />
  );
};

export default SkillsBarChartMolecule;
