import React, { useEffect } from 'react';

import { AccordionDetails, Box } from '@mui/material';

import useI18n from '../../../../../../../hooks/internationalization/useI18n';

import Position from '../../../../../../../types/business/position/Position';
import ActivityPositionFilterData from '../../../../../../../types/business/filter/data/variants/position/ActivityPositionFilterData';

import FilterDialogMoleculeAccordionProps from '../FilterDialogMoleculeAccordionProps';
import CheckboxAtom from '../../../../../atoms/checkboxes/CheckboxAtom';
import CheckboxGroupAtom from '../../../../../atoms/checkboxes/group/CheckboxGroupAtom';
import BaseFilterDialogMoleculeAccordion from '../base/BaseFilterDialogMoleculeAccordion';

import { ACTIVITY_POSITION_FILTER } from '../../../../../../../types/business/filter/data/prototype/fields/FilterType';
import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { DIALOG_FILTER_ACCORDION_ACTIVITY_POSITION_TITLE_MAPPING } from '../../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';

type ActivityPositionFilterDialogMoleculeAccordionProps =
  FilterDialogMoleculeAccordionProps<ActivityPositionFilterData>;

const ActivityPositionFilterDialogMoleculeAccordion: React.FC<
  ActivityPositionFilterDialogMoleculeAccordionProps
> = ({
  className,
  isExpanded,
  initialFilterData,
  currentFilterData,
  onChange,
  onChangeExpand,
}) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);

  const [accordionFilterData, setAccordionFilterData] = React.useState<
    ActivityPositionFilterData[]
  >([]);

  useEffect(() => {
    setAccordionFilterData(currentFilterData);
  }, [currentFilterData]);

  const resetFilter = () => {
    setAccordionFilterData([]);
    onChange([]);
  };

  const handleClick = (position: Position) => {
    const newFilterData = [...accordionFilterData];

    const filterDataPosition = newFilterData.findIndex(
      (filterData) => filterData.data.id === position.id,
    );
    if (filterDataPosition === -1) {
      newFilterData.push({
        type: ACTIVITY_POSITION_FILTER,
        data: position,
      });
    } else {
      newFilterData.splice(filterDataPosition, 1);
    }

    setAccordionFilterData(newFilterData);
    onChange(newFilterData);
  };

  const renderCheckbox = (position: Position) => (
    <Box
      className={`${position.name}-activity-position-checkbox__wrapper`}
      key={position.name}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleClick(position);
      }}>
      <CheckboxAtom
        className={`${position.name}-activity-position-checkbox`}
        name={position.id}
        checked={accordionFilterData.every(
          (filterData) => filterData.data.id !== position.id,
        )}
        label={position.name}
      />
    </Box>
  );

  const renderAvailableCheckboxes = () =>
    initialFilterData.map((filterData) => renderCheckbox(filterData.data));

  return (
    <BaseFilterDialogMoleculeAccordion
      className={`${className} activity-position-accordion`}
      id='activity-position'
      isExpanded={isExpanded}
      title={t(DIALOG_FILTER_ACCORDION_ACTIVITY_POSITION_TITLE_MAPPING)}
      data={currentFilterData}
      onChangeExpand={onChangeExpand}
      onReset={resetFilter}>
      <AccordionDetails className='activity-position-accordion__details'>
        <CheckboxGroupAtom className='details__group' row>
          {renderAvailableCheckboxes()}
        </CheckboxGroupAtom>
      </AccordionDetails>
    </BaseFilterDialogMoleculeAccordion>
  );
};

export default ActivityPositionFilterDialogMoleculeAccordion;
