import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import moment from 'moment';
import 'moment/locale/ru';

import {
  LocaleNamespace,
  LocaleNamespaceMappings,
} from '../../tools/internationalization/i18n/localization/namespaces/LocaleNamespace';

import FixedDateFormat from '../../tools/timedate/date/formats/FixedDateFormat';
import NumberDate from '../../tools/timedate/date/NumberDate';
import DateFormat from '../../tools/timedate/date/formats/DateFormat';
import Duration from '../../tools/timedate/duration/Duration';
import HumanizeDurationFormats from '../../tools/internationalization/humanizeDuration/HumanizeDurationFormats';

import humanizeDuration from '../../tools/internationalization/humanizeDuration/humanizeDuration';
import getI18nLanguage from '../../tools/internationalization/i18n/utils/getI18nLanguage';
import fromDateToMoment from '../../tools/timedate/date/converters/fromDateToMoment';
import fromDurationToMomentDuration from '../../tools/timedate/duration/converters/fromDurationToMomentDuration';

import { L_LOCALE_DATE_FORMAT } from '../../tools/timedate/date/formats/LocaleDateFormat';

interface UseInternationalization<T> {
  /**
   * Get translation according to the current locale.
   * @param key Mapping as string literal in the locale namespace.
   * @returns Translated string.
   */
  t(key: T): string;

  /**
   * Format date according to the current locale.
   * @param date Date to be formatted.
   * @param format Formatting options.
   */
  daf(date: NumberDate, format?: DateFormat): string;

  /**
   * Format duration according to the current locale.
   * @param duration Duration to be humanized.
   * @param format Formatting options.
   */
  duf(duration: Duration, format?: HumanizeDurationFormats): string;

  /**
   * Current locale fixed date format.
   */
  fdf: FixedDateFormat;
}

const useI18n = <T extends LocaleNamespaceMappings>(
  namespace: LocaleNamespace<T>,
): UseInternationalization<T> => {
  const { t } = useTranslation(namespace.name);
  const translate = (key: T): string => t(key as string);

  const dateFormat = (date: NumberDate, format?: DateFormat): string =>
    fromDateToMoment(date).locale(getI18nLanguage()).format(format);

  const durationFormat = (
    duration: Duration,
    format?: HumanizeDurationFormats,
  ): string =>
    humanizeDuration(
      fromDurationToMomentDuration(duration).asMilliseconds(),
      format,
    );

  const localeFixedDateFormat: FixedDateFormat = useMemo(
    () =>
      moment.localeData(getI18nLanguage()).longDateFormat(L_LOCALE_DATE_FORMAT),
    [],
  );

  return {
    t: translate,
    daf: dateFormat,
    duf: durationFormat,
    fdf: localeFixedDateFormat,
  };
};

export default useI18n;
