export const SKILLS_APP_TAB_KEY = 'skills';
export const POSITIONS_APP_TAB_KEY = 'positions';
export const ACTIVITIES_APP_TAB_KEY = 'activities';

type AppTabKey =
  | typeof SKILLS_APP_TAB_KEY
  | typeof POSITIONS_APP_TAB_KEY
  | typeof ACTIVITIES_APP_TAB_KEY;

export default AppTabKey;
