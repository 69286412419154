import Specialization from '../Specialization';
import isObject from '../../../common/guards/isObject';

const isSpecialization = (
  specialization: unknown,
): specialization is Specialization =>
  isObject(specialization) &&
  (specialization as Specialization).id !== undefined &&
  (specialization as Specialization).name !== undefined;

export default isSpecialization;
