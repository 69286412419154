import React, { ReactElement } from 'react';

import { Box, Stack } from '@mui/material';
import { ContentCopyOutlined } from '@mui/icons-material';

import useI18n from '../../../../../hooks/internationalization/useI18n';

import { ComponentProps } from '../../../ComponentProps';
import TypographyAtom from '../../../atoms/typography/TypographyAtom';
import IconButtonAtom from '../../../atoms/buttons/icon/IconButtonAtom';
import TooltipAtom from '../../../atoms/tooltips/TooltipAtom';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import {
  TEXT_COPYABLE_TOOLTIP_FAILURE_MAPPING,
  TEXT_COPYABLE_TOOLTIP_SUCCESS_MAPPING,
} from '../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespaceMappings';
import AvatarAtom from '../../../atoms/avatars/AvatarAtom';

type CopyableTextMoleculeProps = ComponentProps & {
  icon?: ReactElement;
  variant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'overline'
    | 'inherit';
  children: string;
};

const CopyableTextMolecule: React.FC<CopyableTextMoleculeProps> = ({
  className,
  icon,
  variant,
  children,
}) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);

  const [tooltipText, setTooltipText] = React.useState(
    t(TEXT_COPYABLE_TOOLTIP_SUCCESS_MAPPING),
  );
  const [isTooltipOpened, setIsTooltipOpened] = React.useState(false);

  const handleTooltipOpen = () => {
    setIsTooltipOpened(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpened(false);
  };

  const temporarilyShowTooltip = () => {
    handleTooltipOpen();
    setTimeout(handleTooltipClose, 1000);
  };

  const showSuccessTooltip = () => {
    setTooltipText(t(TEXT_COPYABLE_TOOLTIP_SUCCESS_MAPPING));
    temporarilyShowTooltip();
  };

  const showFailureTooltip = () => {
    setTooltipText(t(TEXT_COPYABLE_TOOLTIP_FAILURE_MAPPING));
    temporarilyShowTooltip();
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard
        .writeText(children)
        .then(() => showSuccessTooltip())
        .catch((e) => {
          throw new Error(e);
        });
    } catch (error) {
      showFailureTooltip();
    }
  };

  return (
    <Stack
      className={`${className} copyable-text`}
      direction='row'
      alignItems='center'
      spacing={1}>
      {icon ? (
        <AvatarAtom
          className='copyable-text__icon-avatar'
          shape='rectangular'
          sx={{
            width: '32px',
            justifyContent: 'start',
            color: 'inherit',
            background: 'transparent',
          }}>
          {icon}
        </AvatarAtom>
      ) : null}
      <TypographyAtom className='copyable-text__content' variant={variant}>
        {children}
      </TypographyAtom>
      <TooltipAtom title={tooltipText} open={isTooltipOpened} arrow>
        <Box
          className='copyable-text__button-wrapper'
          onClick={() => copyToClipboard()}>
          <IconButtonAtom className='copyable-text__button' color='primary'>
            <ContentCopyOutlined />
          </IconButtonAtom>
        </Box>
      </TooltipAtom>
    </Stack>
  );
};
export default CopyableTextMolecule;
