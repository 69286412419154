import React from 'react';

import { AccordionSummary, Stack } from '@mui/material';

import useI18n from '../../../../../../../hooks/internationalization/useI18n';
import useTimeDateI18n from '../../../../../../../hooks/internationalization/useTimeDateI18n';
import useDynamicResources from '../../../../../../../hooks/resources/useDynamicResources';

import CvActivity from '../../../../../../../types/business/cv/fields/activity/CvActivity';
import mapActivityTypeToLocaleMapping from '../../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/mappings/mapActivityTypeToLocaleMapping';

import { ComponentProps } from '../../../../../ComponentProps';
import AvatarAtom from '../../../../../atoms/avatars/AvatarAtom';
import TypographyAtom from '../../../../../atoms/typography/TypographyAtom';
import ExpandButtonMolecule from '../../../../buttons/expand/ExpandButtonMolecule';

import { MOLECULES_LOCALE_NAMESPACE } from '../../../../../../../tools/internationalization/i18n/localization/namespaces/molecules/MoleculesLocaleNamespace';
import { ll_LOCALE_DATE_FORMAT } from '../../../../../../../tools/timedate/date/formats/LocaleDateFormat';

type SummaryOfActivityAccordionCardMoleculeProps = ComponentProps & {
  activity: CvActivity;
  expanded?: boolean;
  onExpand?: () => void;
};

const SummaryOfActivityAccordionCardMolecule: React.FC<
  SummaryOfActivityAccordionCardMoleculeProps
> = ({ className, activity, expanded, onExpand }) => {
  const { t } = useI18n(MOLECULES_LOCALE_NAMESPACE);
  const { dai } = useTimeDateI18n();
  const { buildUrlFromImageResourceSource } = useDynamicResources();

  const renderActivityAvatar = () => {
    let avatarSource;
    let avatarAlt;
    let avatarShape;
    if (activity.project) {
      if (activity.project.avatar) {
        avatarSource = activity.project.avatar.src;
        avatarAlt = activity.project.avatar.alt;
        avatarShape = activity.project.avatar.shape;
      } else {
        avatarAlt = activity.project.name;
      }
    } else if (activity.provider) {
      if (activity.provider.avatar) {
        avatarSource = activity.provider.avatar.src;
        avatarAlt = activity.provider.avatar.alt;
        avatarShape = activity.provider.avatar.shape;
      } else {
        avatarAlt = activity.provider.name;
      }
    }

    return (
      <AvatarAtom
        className='activity-accordion-summary__avatar'
        src={
          avatarSource
            ? buildUrlFromImageResourceSource(avatarSource)
            : undefined
        }
        alt={avatarAlt}
        shape={avatarShape}
        sx={{ width: '80px', height: '80px', fontSize: '250%' }}
      />
    );
  };

  const renderActivityPeriodAndType = () => (
    <Stack
      className='activity-accordion-summary__period-and-type'
      direction='column'
      spacing={1}>
      <TypographyAtom
        className='activity-accordion-summary__period'
        variant='subtitle1'
        align='right'
        cursor='pointer'>
        {`${dai(activity.period.start, ll_LOCALE_DATE_FORMAT)} 
        - ${dai(activity.period.end, ll_LOCALE_DATE_FORMAT)}`}
      </TypographyAtom>

      <TypographyAtom
        className='activity-accordion-summary__type'
        variant='subtitle1'
        align='right'
        cursor='pointer'>
        {t(mapActivityTypeToLocaleMapping(activity.type))}
      </TypographyAtom>
    </Stack>
  );

  const renderHeader = () => (
    <Stack
      className='activity-accordion-summary__header'
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      spacing={1}>
      {renderActivityAvatar()}
      {renderActivityPeriodAndType()}
    </Stack>
  );

  const renderHeading = () => (
    <Stack
      className='activity-accordion-summary__project-heading'
      direction='row'
      justifyContent='space-between'
      alignItems='flex-end'>
      <Stack
        className='activity-accordion-summary__project-headings'
        direction='column'>
        <TypographyAtom
          className='activity-accordion-summary__title'
          component='h4'
          variant='h4'
          cursor='pointer'>
          {activity.project ? activity.project.name : activity.provider?.name}
        </TypographyAtom>
        {activity.project && activity.provider && (
          <TypographyAtom
            className='activity-accordion-summary__provider'
            component='h5'
            variant='h5'
            cursor='pointer'>
            {activity.provider?.name}
          </TypographyAtom>
        )}
        <TypographyAtom
          className='activity-accordion-summary__position'
          component='h6'
          variant='h6'
          cursor='pointer'>
          {activity.position?.name}
        </TypographyAtom>
      </Stack>
      <ExpandButtonMolecule
        className='activity-accordion-summary__expand-button'
        expanded={expanded}
        onClick={onExpand}
      />
    </Stack>
  );

  return (
    <AccordionSummary
      className={`${className} activity-accordion-summary`}
      aria-controls={`activity-${activity.id}-content`}
      id={`activity-${activity.id}-header`}
      onClick={onExpand}
      sx={{
        padding: 0,
        '& .MuiAccordionSummary-content': { margin: 0 },
        '& .MuiAccordionSummary-content.Mui-expanded': {
          margin: 0,
        },
      }}>
      <Stack
        className='activity-accordion-summary__container'
        direction='column'
        width='100%'
        spacing={2}>
        {renderHeader()}
        {renderHeading()}
      </Stack>
    </AccordionSummary>
  );
};

export default SummaryOfActivityAccordionCardMolecule;
