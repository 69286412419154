import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../storage/reducers/rootReducer';
import useRootDispatch from '../../../storage/dispatch/rootDispatch';
import setInitialCvUser from '../../../storage/actions/cv/set/user/setInitialCvUser';
import setActualCvUser from '../../../storage/actions/cv/set/user/setActualCvUser';
import setInitialCvActivities from '../../../storage/actions/cv/set/activities/setInitialCvActivities';
import setActualCvActivities from '../../../storage/actions/cv/set/activities/setActualCvActivities';

import useStaticResourcesLoader from '../../resources/useStaticResourcesLoader';
import useStaticResources from '../../resources/useStaticResources';

import ConstructFlow from '../../../tools/constructed/ConstructFlow';
import ConstructStage from '../../../tools/constructed/ConstructStage';
import removeStagesFromFlow from '../../../tools/constructed/utils/removeStagesFromFlow';
import wasStageRequirementsFulfilled from '../../../tools/constructed/utils/wasStageRequirementsFulfilled';
import isStageExistsInFlow from '../../../tools/constructed/utils/isStageExistsInFlow';

import toCvUser from '../../../types/business/cv/fields/user/constructed/converters/toCvUser';
import toCvActivities from '../../../types/business/cv/fields/activity/constructed/converters/toCvActivities';
import createConstructedUserFromResource from '../../../types/business/cv/fields/user/constructed/operations/createConstructedUserFromResource';
import updateConstructedUserWithPositionsResource from '../../../types/business/cv/fields/user/constructed/operations/updateConstructedUserWithPositionsResource';
import createConstructedActivityFromResource from '../../../types/business/cv/fields/activity/constructed/operations/createConstructedActivityFromResource';
import updateConstructedActivityWithPositionResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithPositionResource';
import updateConstructedActivityWithProjectResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithProjectResource';
import updateConstructedActivityWithProviderResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithProviderResource';
import updateConstructedActivityWithSubactivitiesResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithSubactivitiesResource';
import updateConstructedActivityWithSkillsResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithSkillsResource';
import updateConstructedUserWithTagsResource from '../../../types/business/cv/fields/user/constructed/operations/updateConstructedUserWithTagsResource';
import updateConstructedActivityWithTagsResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithTagsResource';
import updateConstructedUserWithSpecializationsResource from '../../../types/business/cv/fields/user/constructed/operations/updateConstructedUserWithSpecializationsResource';
import updateConstructedActivityWithSpecializationsResource from '../../../types/business/cv/fields/activity/constructed/operations/updateConstructedActivityWithSpecializationsResource';

import { CV_USER_BASE_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/user/constructed/stages/CvUserBaseConstructStage';
import { CV_USER_POSITIONS_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/user/constructed/stages/CvUserPositionsConstructStage';
import { CV_ACTIVITY_BASE_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityBaseConstructStage';
import { CV_ACTIVITY_POSITION_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityPositionConstructStage';
import { CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityProjectConstructStage';
import { CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityProviderConstructStage';
import { CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivitySubactivitiesConstructStage';
import { CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivitySkillsConstructStage';
import { CV_USER_TAGS_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/user/constructed/stages/CvUserTagsConstructStage';
import { CV_ACTIVITY_TAGS_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivityTagsConstructStage';
import { CV_USER_SPECIALIZATION_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/user/constructed/stages/CvUserSpecializationConstructStage';
import { CV_ACTIVITY_SPECIALIZATION_CONSTRUCT_STAGE } from '../../../types/business/cv/fields/activity/constructed/stages/CvActivitySpecializationConstructStage';

/**
 * @see useCvLoader - Producer of the useCvLoaderEffects events
 */
const useCvLoaderEffects = () => {
  const dispatch = useRootDispatch();
  const {
    loadUserResource,
    loadActivitiesResource,
    loadSubactivitiesResource,
    loadProjectsResource,
    loadProvidersResource,
    loadPositionsResource,
    loadSkillsResource,
    loadTagsResource,
    loadSpecializationsResource,
  } = useStaticResourcesLoader();
  const {
    cv: cvResource,
    user: userResource,
    activities: activitiesResource,
    subactivities: subactivitiesResource,
    projects: projectsResource,
    providers: providersResource,
    positions: positionsResource,
    skills: skillsResource,
    tags: tagsResource,
    specializations: specializationsResource,
  } = useStaticResources();
  const { cvUser, cvActivities } = useSelector(
    (state: RootState) => state.cvReducer,
  );

  // Load of missing static resources based on a single requester
  useEffect(() => {
    if (cvResource && !userResource) {
      loadUserResource();
    }
  }, [cvResource, userResource, loadUserResource]);

  useEffect(() => {
    if (cvResource && !activitiesResource) {
      loadActivitiesResource();
    }
  }, [cvResource, activitiesResource, loadActivitiesResource]);

  useEffect(() => {
    if (activitiesResource && !projectsResource) {
      loadProjectsResource();
    }
  }, [activitiesResource, projectsResource, loadProjectsResource]);

  useEffect(() => {
    if (activitiesResource && !providersResource) {
      loadProvidersResource();
    }
  }, [activitiesResource, providersResource, loadProvidersResource]);

  useEffect(() => {
    if (activitiesResource && !subactivitiesResource) {
      loadSubactivitiesResource();
    }
  }, [activitiesResource, subactivitiesResource, loadSubactivitiesResource]);

  useEffect(() => {
    if (positionsResource && !skillsResource) {
      loadSkillsResource();
    }
  }, [positionsResource, skillsResource, loadSkillsResource]);

  useEffect(() => {
    if (positionsResource && !specializationsResource) {
      loadSpecializationsResource();
    }
  }, [positionsResource, specializationsResource, loadSpecializationsResource]);

  // Load of missing static resources based on multiple requesters
  useEffect(() => {
    if ((userResource || activitiesResource) && !positionsResource) {
      loadPositionsResource();
    }
  }, [
    userResource,
    activitiesResource,
    positionsResource,
    loadPositionsResource,
  ]);

  useEffect(() => {
    if ((userResource || skillsResource) && !tagsResource) {
      loadTagsResource();
    }
  }, [userResource, skillsResource, tagsResource, loadTagsResource]);

  const onStage = <T, R extends T>(
    stage: ConstructStage<T, R>,
    flow: ConstructFlow<T, R>,
    callback: () => void,
  ) => {
    if (
      isStageExistsInFlow(stage, flow) &&
      wasStageRequirementsFulfilled(stage, flow)
    ) {
      callback();
    }
  };

  // Initial CV user live loading and updating

  useEffect(() => {
    onStage(CV_USER_BASE_CONSTRUCT_STAGE, cvUser.initial.flow, () => {
      if (userResource) {
        dispatch(
          setInitialCvUser({
            flow: removeStagesFromFlow(
              [CV_USER_BASE_CONSTRUCT_STAGE],
              cvUser.initial.flow,
            ),
            value: createConstructedUserFromResource(userResource),
          }),
        );
      }
    });
  }, [cvUser.initial.flow, userResource, dispatch]);

  useEffect(() => {
    onStage(CV_USER_POSITIONS_CONSTRUCT_STAGE, cvUser.initial.flow, () => {
      if (cvUser.initial.value && positionsResource) {
        dispatch(
          setInitialCvUser({
            flow: removeStagesFromFlow(
              [CV_USER_POSITIONS_CONSTRUCT_STAGE],
              cvUser.initial.flow,
            ),
            value: updateConstructedUserWithPositionsResource(
              cvUser.initial.value,
              positionsResource,
            ),
          }),
        );
      }
    });
  }, [cvUser.initial, positionsResource, dispatch]);

  useEffect(() => {
    onStage(CV_USER_SPECIALIZATION_CONSTRUCT_STAGE, cvUser.initial.flow, () => {
      if (cvUser.initial.value && specializationsResource) {
        dispatch(
          setInitialCvUser({
            flow: removeStagesFromFlow(
              [CV_USER_SPECIALIZATION_CONSTRUCT_STAGE],
              cvUser.initial.flow,
            ),
            value: updateConstructedUserWithSpecializationsResource(
              cvUser.initial.value,
              specializationsResource,
            ),
          }),
        );
      }
    });
  }, [cvUser.initial, specializationsResource, dispatch]);

  useEffect(() => {
    onStage(CV_USER_TAGS_CONSTRUCT_STAGE, cvUser.initial.flow, () => {
      if (cvUser.initial.value && tagsResource) {
        dispatch(
          setInitialCvUser({
            flow: removeStagesFromFlow(
              [CV_USER_TAGS_CONSTRUCT_STAGE],
              cvUser.initial.flow,
            ),
            value: updateConstructedUserWithTagsResource(
              cvUser.initial.value,
              tagsResource,
            ),
          }),
        );
      }
    });
  }, [cvUser.initial, tagsResource, dispatch]);

  useEffect(() => {
    dispatch(setActualCvUser(toCvUser(cvUser.initial.value)));
  }, [cvUser.initial.value, dispatch]);

  // Initial CV activities live loading and updating

  useEffect(() => {
    onStage(CV_ACTIVITY_BASE_CONSTRUCT_STAGE, cvActivities.initial.flow, () => {
      if (activitiesResource) {
        dispatch(
          setInitialCvActivities({
            flow: removeStagesFromFlow(
              [CV_ACTIVITY_BASE_CONSTRUCT_STAGE],
              cvActivities.initial.flow,
            ),
            value: activitiesResource.map((activity) =>
              createConstructedActivityFromResource(activity),
            ),
          }),
        );
      }
    });
  }, [cvActivities.initial.flow, activitiesResource, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_POSITION_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && positionsResource) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_POSITION_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithPositionResource(
                  activity,
                  positionsResource,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, positionsResource, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_SPECIALIZATION_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && specializationsResource) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_SPECIALIZATION_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithSpecializationsResource(
                  activity,
                  specializationsResource,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, specializationsResource, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && projectsResource) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_PROJECT_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithProjectResource(
                  activity,
                  projectsResource,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, projectsResource, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && providersResource) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_PROVIDER_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithProviderResource(
                  activity,
                  providersResource,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, providersResource, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && subactivitiesResource) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_SUBACTIVITIES_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithSubactivitiesResource(
                  activity,
                  subactivitiesResource,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, subactivitiesResource, dispatch]);

  useEffect(() => {
    onStage(
      CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE,
      cvActivities.initial.flow,
      () => {
        if (cvActivities.initial.value && skillsResource) {
          dispatch(
            setInitialCvActivities({
              flow: removeStagesFromFlow(
                [CV_ACTIVITY_SKILLS_CONSTRUCT_STAGE],
                cvActivities.initial.flow,
              ),
              value: cvActivities.initial.value.map((activity) =>
                updateConstructedActivityWithSkillsResource(
                  activity,
                  skillsResource,
                ),
              ),
            }),
          );
        }
      },
    );
  }, [cvActivities.initial, skillsResource, dispatch]);

  useEffect(() => {
    onStage(CV_ACTIVITY_TAGS_CONSTRUCT_STAGE, cvActivities.initial.flow, () => {
      if (cvActivities.initial.value && tagsResource) {
        dispatch(
          setInitialCvActivities({
            flow: removeStagesFromFlow(
              [CV_ACTIVITY_TAGS_CONSTRUCT_STAGE],
              cvActivities.initial.flow,
            ),
            value: cvActivities.initial.value.map((activity) =>
              updateConstructedActivityWithTagsResource(activity, tagsResource),
            ),
          }),
        );
      }
    });
  }, [cvActivities.initial, tagsResource, dispatch]);

  useEffect(() => {
    dispatch(setActualCvActivities(toCvActivities(cvActivities.initial.value)));
  }, [cvActivities.initial.value, dispatch]);
};

export default useCvLoaderEffects;
