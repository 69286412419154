import CvUser from '../../CvUser';
import ConstructedCvUser from '../ConstructedCvUser';

import toCvUserPosition from '../../fields/position/constructed/converters/toCvUserPosition';
import isTagGroups from '../../../../../tag/group/guards/isTagGroups';

const toCvUser = (constructedCvUser?: ConstructedCvUser): CvUser | undefined =>
  constructedCvUser && {
    ...constructedCvUser,
    position: toCvUserPosition(constructedCvUser.position),
    preferences: constructedCvUser.preferences && {
      contacts: constructedCvUser.preferences.contacts,
      tagGroups: isTagGroups(constructedCvUser.preferences.tagGroups)
        ? constructedCvUser.preferences.tagGroups
        : undefined,
    },
  };

export default toCvUser;
