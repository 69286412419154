import CvActivityPositionSpecializationId from '../fields/CvActivityPositionSpecializationId';
import ConstructedCvActivityPosition from '../ConstructedCvActivityPosition';
import CvActivityPosition from '../../CvActivityPosition';

import isPosition from '../../../../../../../position/guards/isPosition';

const toCvActivityPosition = (
  position?: CvActivityPositionSpecializationId | ConstructedCvActivityPosition,
): CvActivityPosition | undefined => {
  if (isPosition(position)) {
    return position as CvActivityPosition;
  }

  return undefined;
};

export default toCvActivityPosition;
